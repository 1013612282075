import React, { useEffect, useState } from "react";
import LinkFile from "./LinkFile";
import { Box } from "@material-ui/core";
import { connect } from "react-redux";
import RightSide from "./RightSide/RightSide";
import LeftSide from "./LeftSide/LeftSide";
import {
  fetchContactAdditionalContacts,
  getAllContacts,
  setContactDetails,
  updateMultipleData,
} from "../../actions/contactAction";
import {
  addSelectedContact,
  setContactIdFromDashboard,
  setUser,
} from "./LeftSide/inboxAction";

import { fetchContactDetailsNew, getUserInfo } from "../../api/contactApi";
import Utils from "./Helpers/Utils";
import ConversationTopBar from "./LeftSide/conversationTopBar/ConversationTopBar";
import LinkFileForDeal from "./LinkFileForDeal";
// import '../GlobalContactDetailsForDeal/LeftSide/inbox-new.css';

const Details = (props) => {
  const [contactId, setContactId] = useState(props.id);
  const [data, setData] = useState(null);
  const [show, setShow] = useState(props.open)

  useEffect(() => {
    loadData(contactId);
    getAdditionalContactList(contactId);
    loadUserInfo();
  }, []);

  const loadData = (id) => {
    fetchContactDetailsNew({
      id: id ?? contactId,
      for_custom_field_update: true,
    })
      .then((response) => {
        if (response.data.status === "success") {
          if (
            response.data.data !== undefined &&
            response.data.data != null &&
            response.data.data["people"] !== undefined &&
            response.data.data["people"] != null
          ) {
            setData(response.data.data);
            // changeContactCondition(true, contactId, false)
            props.addSelectedContact({
              contact: response.data.data["people"],
              index: null,
            });
            props.setContactDetails(response.data.data["people"]);
          } else {
            window.showNotification(
              "ERROR",
              "You don't have access to this contact"
            );
          }
        } else {
          window.showNotification("ERROR", response.data.message);
        }
      })
      .finally(() => {});
  };
  const loadUserInfo = () => {
    getUserInfo({})
      .then((response) => {
        let result = response.data;

        props.storeUser(result);
      })
      .catch((error) => {
        Utils.handleException(error);
      });
  };

  const getAdditionalContactList = (id) => {
    props.fetchContactAdditionalContacts({
      page_no: 1,
      per_page: 100,
      contact_id: id,
    });
  };

  const closeModal = () => {
    setShow(false)
    setTimeout(()=>{
      props.onClose(false)
  },500)
  };

  const element = (
    <>
     
          <LinkFileForDeal.modal
            open={props.open}
            onClose={()=>{
              props.onClose()
            }}
            hideFooter={true}
            // className="global-large-modal"
            extra_props_active={true}
            show={show}
            setShow={setShow}
          >
            <Box display={"flex"} sx={{gap:'4px'}}>
              <Box width={"68%"}>
              <ConversationTopBar
                handleClose={closeModal}
                dealCallBack={props.dealCallback}
                contactDetails={data != null ? data["people"] : null}
              />
                <LeftSide handleClose={closeModal}/>
              </Box>
              <Box width={"32%"} padding={'12px'}>
                <RightSide
                  closeModal={closeModal}
                  contactDetails={data != null ? data["people"] : null}
                />
              </Box>
            </Box>
          </LinkFileForDeal.modal>
        </>
  );

  return (
   <Box>
    {element}
   </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    contact: state.addContactReducer.contact,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setContactDetails: (params) => dispatch(setContactDetails(params)),
    updateMultipleData: (params) => dispatch(updateMultipleData(params)),
    getAllContactList: (params, callback) =>dispatch(getAllContacts(params, callback)),
    fetchContactAdditionalContacts: (params) =>dispatch(fetchContactAdditionalContacts(params)),
    storeUser: (params, callBack) => dispatch(setUser(params, callBack)),
    setContactIdFromDashboard: (params) =>dispatch(setContactIdFromDashboard(params)),
    addSelectedContact: (params) => dispatch(addSelectedContact(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Details);

import { sendRequest, sendRequestBackEnd } from '../../../api/rootApi';
import Api from "./Api";

export const fetchConversation = async (payload) => {
    return sendRequest("post", Api.conversations, payload);
}
export const formBuilderAWSPreSignedUrl = async (payload) =>{
    return sendRequest("post", Api.formBuilderAWSPreSignedUrl, payload);
}
export const sendMessage = async (payload) => {
    return sendRequest("post", Api.sendMessage, payload);
}
export const markAsImportant = async (payload) => {
    return sendRequest("post", Api.markAsImportant, payload);
}
export const deleteContact = async (payload) => {
    return sendRequest("post", Api.deleteContact, payload);
}
export const archiveContact = async (payload) => {
    return sendRequest("post", Api.archiveContact, payload);
}
export const markAsUnread = async (payload) => {
    return sendRequest("post", Api.markAsUnread, payload);
}
export const fetchVirtualNumber = async (payload) => {
    return sendRequest("post", Api.virtualNumbers, payload);
}

import React, {Fragment, useEffect, useState} from "react";
import {
    Backdrop,
    Grid,
    IconButton,
    Typography
} from "@material-ui/core";
import {
    DeleteForever as DeleteForeverIcon,
} from "@material-ui/icons";
import {
    AddContactFooter,
    AddContactSave,
    AddressManualButton,
    AddressSearchButton,
    AddressSearchToggleWrapper,
    BasicAddButton2,
    BasicCloseButton,
    BasicFormLabel,
    BasicInput,
    BasicMaskedInput,
    ContactAddModal,
    ContactAddModalBody,
    ContactAddModalHeader,
    ContactAddModalWrapper,
    ContactCustomFieldGrid,
    DynamicFieldWrapper,
    GooglePlacesAutocompleteContainer,
    GooglePlacesAutocompleteIcon,
    GooglePlacesAutocompleteIconWrapper,
    GooglePlacesAutocompleteInput,
    GooglePlacesAutocompleteWrapper,
    HeadlineWrapper
} from "../GlobalAddDealModalStyles";
import BasicSelect from "./BasicSelect";
import {AddMoreIcon, CloseIcon} from "./Icons";
import {LEAD_TYPES, PHONE_TYPES} from "../../../constants/CoreConstants";
import {IconList} from "../../addContact/IconList";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import Utils, {getState} from "../../addContact/Utils";
import {US_STATE_LIST} from "../../addContact/CoreConstants";
import {renderEachCustomField} from "../../addContact/AddContactModal";
import {
    getUserCustomFieldList,
    getUserSourceList,
    getUserTagList,
    setUserCustomFieldList,
    setUserSourceList,
    setUserTagList,
    storeUserCustomFieldsListNew
} from "../../addContact/addContactAction";
import {connect} from "react-redux";
import {
    getContactLimit,
    getZipCodeByPlace,
    submitSingleContactV2
} from "../../addContact/contactApi";
import moment from "moment/moment";

const GOOGLE_API_KEY = process.env.REACT_APP_MAP_KEY;

const ContactAdd = (props) => {
    const { open, onClose, callback } = props;
    const [firstLoading, setFirstLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showAddress, setShowAddress] = useState(false);
    const [customFields, setCustomFields] = useState([]);
    const [leadSources, setLeadSources] = useState([]);
    const [contactLimit, setContactLimit] = useState(0);
    const [currentContact, setCurrentContact] = useState(0);
    const [formData, setFormData] = useState({
        selected_source: "",
        lead_type: "",
        company_name: "",
        first_name: "",
        last_name: "",
        numbers: [
            {
                number: "",
                number_state: "mobile"
            }
        ],
        emails: [
            {
                email: ""
            }
        ],
        birth_date: "",
        anniversary_date: "",
        address: "",
        city: "",
        state: "",
        country: "",
        zip: "",
        important_dates: [],
        customFields: [],
    });
    const [additionalDatesErrors, setAdditionalDatesErrors] = useState([]);
    const placeholderColor =  "#929da9";
    const activeColor = "#36454F";

    useEffect(() => {
        if (firstLoading) {
            setIsLoading(true);
            setFirstLoading(false);

            getContactLimit().then((res) => {
                setIsLoading(false);
                let data = res.data.data;
                if (data.contactLimit > data.currentContact) {
                    fetchSourceInfos().then();
                    fetchUserCustomFields().then();
                }
                if (data.contactLimit) {
                    setContactLimit(data.contactLimit);
                    setCurrentContact(data.currentContact);
                }
            });
        }
    }, []);

    const handleResetFormData = () => {
      setFormData({
          selected_source: "",
          lead_type: "",
          company_name: "",
          first_name: "",
          last_name: "",
          numbers: [
              {
                  number: "",
                  number_state: "mobile"
              }
          ],
          emails: [
              {
                  email: ""
              }
          ],
          birth_date: "",
          anniversary_date: "",
          address: "",
          city: "",
          state: "",
          country: "",
          zip: "",
          important_dates: [],
          customFields: [],
      });
    };

    const handleCloseModal = () => {
        handleResetFormData();
        onClose();
    };

    const handleChangeFormData = (key, value) => {
        setFormData((prevState)=>({ ...prevState, [key]: value }));
    };

    const handleChangePhoneNumber = (index, key, value) => {
      const old = [...formData.numbers];
        if(!Utils.checkLetterExistInText(value) || value === "") {
            if (value.length > 0 && value[0] !== "1") {
                value = 1 + value;
            }
        }else {
            try {
                value = value.replace(/[^0-9]+/g, "");
                if (value.length > 0 && value[0] !== "1") {
                    value = 1 + value;
                }
            } catch (e) {
                console.log(e);
            }
        }

        if (value.length > 11) {
            value = value.slice(0, 11);
        }

      old[index][key] = value;
      handleChangeFormData("numbers", old);
    };

    const addMorePhoneNumber = () => {
        const old = [...formData.numbers];

        old.push({
            number: "",
            number_state: "mobile"
        });

        handleChangeFormData("numbers", old);
    };

    const removePhoneNumber = (index) => {
        const old = [...formData.numbers];

        old.splice(index, 1);

        handleChangeFormData("numbers", old);
    };

    const handleChangeEmailAddress = (index, key, value) => {
        const old = [...formData.emails];
        old[index][key] = value;
        handleChangeFormData("emails", old);
    };

    const addMoreEmailAddress = () => {
        const old = [...formData.emails];

        old.push({
            email: "",
        });

        handleChangeFormData("emails", old);
    };

    const removeEmailAddress = (index) => {
        const old = [...formData.emails];

        old.splice(index, 1);

        handleChangeFormData("emails", old);
    };

    const handleChangeImportantDate = (index, key, value) => {
        const old = [...formData.important_dates];
        old[index][key] = value;
        handleChangeFormData("important_dates", old);
    };

    const addMoreImportantDate = () => {
        const old = [...formData.important_dates];

        old.push({
            type: "CUSTOM",
            title: "",
            value: "",
        });

        handleChangeFormData("important_dates", old);
    };

    const removeImportantDate = (index) => {
        const old = [...formData.important_dates];

        old.splice(index, 1);

        handleChangeFormData("important_dates", old);
    };

    const handleCustomFieldChange = (value, indexField, id, maxLength = null) => {
        if ((maxLength != null) & (value.length > maxLength)) {
            return false;
        }

        let old = [...formData.customFields];
        if (value === "") {
            old[indexField] = null;
        } else {
            old[indexField] = {
                user_custom_field_id: id,
                value: value,
            };
        }
        handleChangeFormData("customFields", old);
    };

    const fetchUserCustomFields = async () => {
        if (props.storedCustomField !== null) {
            setCustomFields(props.storedCustomField);
        } else {
            window.globalCustomFieldWithPersonalized({
                    get_custom_data: true,
                    get_personalized_data: false,
                    field_name: ["*"],
                    for_drop_down: false,
                    drop_down_field: {
                        value_field: "id",
                        title_field: "title",
                    },
                }).then((res) => {
                    let customFieldData = res.data.custom_field;
                    let customFieldValues = [];
                    customFieldData.forEach((row, index) => {
                        customFieldValues.push(null);
                    });
                    setCustomFields(customFieldData);
                    handleChangeFormData("customFields", customFieldValues);
                    props.storeUserCustomFieldsNew(customFieldData);
                });
        }
    };

    const fetchSourceInfos = async () => {
        if (props.storedSourceList !== null) {
            setLeadSources(props.storedSourceList);
        } else if (props.sourceList !== undefined && props.sourceList != null) {
            setLeadSources(props.sourceList);
        } else {
            await props.fetchUserSourceList({}, (callback) => {
                setLeadSources(callback);
            });
        }
    };

    const handleAddress = async (event) => {
        let placeId = event.place_id !== undefined && event.place_id != null ? event.place_id : null;
        let zip = "";
        let road = "";
        let streetNumber = "";
        let state = "";
        let city = "";
        let country = "";
        let county = "";

        if (placeId != null) {
            try {
                await getZipCodeByPlace({
                    placeId: placeId,
                    googleAPIKey: GOOGLE_API_KEY,
                }).then((res) => {
                    try {
                        if (
                            res.data.data.result.formatted_address !== undefined &&
                            res.data.data.result.formatted_address != null
                        ) {
                            let data_array =
                                res.data.data.result.formatted_address.split(",");
                            if (data_array.length === 4) {
                                let state_zip = data_array[2].trim().split(" ");
                                setFormData((prevState)=>({
                                    ...prevState,
                                    address: data_array[0].trim(),
                                    city: data_array[1].trim(),
                                    state: state_zip[0],
                                    country: data_array[3],
                                    zip: state_zip[1],
                                }));
                            } else {
                                zip = res?.data?.zip;
                                if (
                                    res.data.data.result.address_components &&
                                    Array.isArray(res.data.data.result.address_components)
                                ) {
                                    res.data.data.result.address_components.map((item, index) => {
                                        if (item.types[0] === "street_number") {
                                            streetNumber = item.long_name;
                                        } else if (item.types[0] === "route") {
                                            road = item.long_name;
                                        } else if (
                                            item.types[0] === "administrative_area_level_1"
                                        ) {
                                            state = item.short_name;
                                        } else if (
                                            item.types[0] === "administrative_area_level_2"
                                        ) {
                                            city = item.long_name;
                                        } else if (item.types[0] === "country") {
                                            country = item.long_name;
                                        } else if (item.types[0] === "postal_code") {
                                            zip = item.long_name;
                                        } else if (
                                            item.types[0] === "administrative_area_level_3"
                                        ) {
                                            county = item.long_name;
                                        }
                                        setFormData((prevState)=>({
                                            ...prevState,
                                            address: streetNumber + " " + road,
                                            city: county !== "" ? county : city,
                                            state: state,
                                            country: country,
                                            zip: zip,
                                        }));
                                    });
                                } else {
                                    updateContactInfo(event, zip);
                                }
                            }
                        } else {
                            zip = res?.data?.zip;
                            if (
                                res.data.data.result.address_components &&
                                Array.isArray(res.data.data.result.address_components)
                            ) {
                                res.data.data.result.address_components.map((item, index) => {
                                    if (item.types[0] === "street_number") {
                                        streetNumber = item.long_name;
                                    } else if (item.types[0] === "route") {
                                        road = item.long_name;
                                    } else if (item.types[0] === "administrative_area_level_1") {
                                        state = item.short_name;
                                    } else if (item.types[0] === "administrative_area_level_2") {
                                        city = item.long_name;
                                    } else if (item.types[0] === "country") {
                                        country = item.long_name;
                                    } else if (item.types[0] === "postal_code") {
                                        zip = item.long_name;
                                    } else if (item.types[0] === "administrative_area_level_3") {
                                        county = item.long_name;
                                    }

                                    setFormData((prevState)=>({
                                        ...prevState,
                                        address: streetNumber + " " + road,
                                        city: county !== "" ? county : city,
                                        state: state,
                                        country: country,
                                        zip: zip,
                                    }));
                                });
                            } else {
                                updateContactInfo(event, zip);
                            }
                        }
                    } catch (error) {
                        zip = res?.data?.zip;
                        if (
                            res.data.data.result.address_components &&
                            Array.isArray(res.data.data.result.address_components)
                        ) {
                            res.data.data.result.address_components.map((item, index) => {
                                if (item.types[0] === "street_number") {
                                    streetNumber = item.long_name;
                                } else if (item.types[0] === "route") {
                                    road = item.long_name;
                                } else if (item.types[0] === "administrative_area_level_1") {
                                    state = item.short_name;
                                } else if (item.types[0] === "administrative_area_level_2") {
                                    city = item.long_name;
                                } else if (item.types[0] === "country") {
                                    country = item.long_name;
                                } else if (item.types[0] === "postal_code") {
                                    zip = item.long_name;
                                } else if (item.types[0] === "administrative_area_level_3") {
                                    county = item.long_name;
                                }

                                setFormData((prevState)=>({
                                    ...prevState,
                                    address: streetNumber + " " + road,
                                    city: county !== "" ? county : city,
                                    state: state,
                                    country: country,
                                    zip: zip,
                                }));
                            });
                        } else {
                            updateContactInfo(event, zip);
                        }
                    }
                });
            } catch (e) {
                updateContactInfo(event, "");
                console.log(e);
            }finally {
                setShowAddress(true);
            }
        }
    };

    const updateContactInfo = (event, zip) => {
        let fullAddressArr = event.description.split(",");
        let address =
            fullAddressArr[0] !== undefined ? fullAddressArr[0].trim() : "";
        let city = fullAddressArr[1] !== undefined ? fullAddressArr[1].trim() : "";
        let fullState =
            fullAddressArr[2] !== undefined ? fullAddressArr[2].trim() : "";
        let country =
            fullAddressArr[3] !== undefined ? fullAddressArr[3].trim() : "";

        let stateArr = fullState.split(" ");
        let state = stateArr[0] !== undefined ? stateArr[0].trim() : "";

        setFormData((prevState)=>({
            ...prevState,
            address: address,
            city: city,
            state: state,
            country: country,
            zip: zip,
        }));
    };

    const makeAddress = () => {
        let address = "";

        address = formData.address;

        if (formData.city) {
            if (address) {
                address += " ";
            }
            address += formData.city;
        }

        if (formData.state) {
            if (address) {
                address += " ";
            }
            address += formData.state;
        }

        if (formData.country) {
            if (address) {
                address += " ";
            }
            address += formData.country;
        }

        if (formData.zip) {
            if (address) {
                address += " ";
            }
            address += formData.zip;
        }

        return address;
    };

    const isDateInvalid = (birth, anniversary) => {
        let birthDate = moment(birth).isValid();
        let anniversaryDate = moment(anniversary).isValid();

        if ((birth && anniversary) || birth || anniversary) {
            if ((birthDate && anniversaryDate) || (birthDate && anniversary == null) || (anniversaryDate && birth == null)) {
                return false;
            } else {
                if (!birthDate && anniversary != null && !anniversaryDate && birth != null) {
                    return "Both birth date & anniversary Date are invalid!";
                } else if (!birthDate && !birthDate && birth != null) {
                    return "Birthdate is invalid!";
                } else if (!anniversaryDate && !anniversaryDate && anniversary != null) {
                    return "Anniversary date is invalid!";
                } else {
                    return "Date is invalid!";
                }
            }
        } else {
            return false;
        }
    };

    const isInvalidFields = (field) => {
        let hasInvalid = false;
        if (field === "number") {
            const tempFormData = { ...formData };
            const tempPhoneNumbers = [...tempFormData["numbers"]];
            tempPhoneNumbers.forEach((additionalNumber) => {
                if (additionalNumber["number"] !== "" && !Utils.validateMobileNumber(additionalNumber["number"])) {
                    hasInvalid = true;
                }
            });
        } else {
            const tempFormData = { ...formData };
            const tempEmailAddresses = [...tempFormData["emails"]];
            tempEmailAddresses.forEach((additionalEmail) => {
                if (additionalEmail["email"] !== "" && !Utils.validateEmail(additionalEmail["email"])) {
                    hasInvalid = true;
                }
            });
        }
        return hasInvalid;
    }

    const validateFields = () => {
        let isValid = true;

        let additionalErrors = [];

        formData.important_dates.forEach((additionalDate) => {
            let err = {
                title: "",
                value: ""
            };
            if (additionalDate.title !== null && additionalDate.title !== undefined && additionalDate.title.trim() === "") {
                err.title = "Date title is required";
                window.showNotification('Error', 'Important date title cannot be empty');
                isValid = false;
            }

            if (additionalDate.value !== null && additionalDate.value !== undefined && additionalDate.value.trim() === "") {
                err.value = "Date is required";
                window.showNotification('Error', 'Important date value cannot be empty');
                isValid = false;
            }

            additionalErrors.push(err);
        });
        setAdditionalDatesErrors(additionalErrors);
        return isValid;
    };

    const haveRequiredFields = () => {
        let hasNumber = false;
        let hasEmail = false;

        const tempFormData = { ...formData };
        const tempPhoneNumbers = [...tempFormData["numbers"]];
        tempPhoneNumbers.forEach((additionalNumber, index) => {
            if (index === 0 && additionalNumber["number"] !== "") {
                hasNumber = true;
            }
        });

        const tempEmailAddresses = [...tempFormData["emails"]];
        tempEmailAddresses.forEach((additionalEmail, index) => {
            if (index === 0 && additionalEmail["email"] !== "") {
                hasEmail = true;
            }
        });

        return hasNumber || hasEmail;
    }

    const submitAddContact = async () => {
        let validation = isDateInvalid(formData.birth_date, formData.anniversary_date);
        if (validation) {
            window.showNotification("Error", validation);
            return;
        }

        if (!validateFields()) return;

        if (!haveRequiredFields()) {
            window.showNotification("Error", "Email and number, Either of them are necessary");
        } else if (isInvalidFields("email")) {
            window.showNotification("Error", "Email is invalid!");
        } else if (isInvalidFields("number")) {
            window.showNotification("Error", "Number is invalid!");
        } else {
            try {
                setIsSubmitting(true);
                const response = await submitSingleContactV2(formData);

                if (response.data.messageStatus === "error") {
                    window.showNotification("Error", response.data.html.email !== undefined ? response.data.html.email :
                        response.data.html.number !== undefined ? response.data.html.number : "Something went wrong");
                } else {
                    window.showNotification("SUCCESS", "Contact added successfully!");
                    callback({ id: response.data.insertedContactId, ...formData });
                    handleCloseModal();
                }
            } catch (e) {
                console.log(e);
            }finally {
                setIsSubmitting(false);
            }
        }
    };

    return(
        <ContactAddModal
            open={open}
            onClose={handleCloseModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 1000,
            }}
        >
            <ContactAddModalWrapper>
                <ContactAddModalHeader>
                    <Typography variant="h5" style={{ color: "#fff", whiteSpace: "nowrap", fontWeight:"600", fontSize:'22px' }}>New Contact</Typography>
                    <BasicCloseButton onClick={handleCloseModal}>
                        <CloseIcon/>
                    </BasicCloseButton>
                </ContactAddModalHeader>
                <ContactAddModalBody className="awesome__scroll_bar">
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <BasicFormLabel variant="body1">
                                Lead Source
                            </BasicFormLabel>
                            <BasicSelect
                                value={formData.selected_source}
                                options={leadSources}
                                mapping={{
                                    label: "source_title",
                                    value: "id"
                                }}
                                defaultText="Select lead source"
                                onChange={(e) => {
                                    handleChangeFormData("selected_source", e.target.value);
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <BasicFormLabel variant="body1">
                                Lead Type
                            </BasicFormLabel>
                            <BasicSelect
                                value={formData.lead_type}
                                options={LEAD_TYPES}
                                mapping={{
                                    label: "label",
                                    value: "value"
                                }}
                                defaultText="Select lead type"
                                onChange={(e) => {
                                    handleChangeFormData("lead_type", e.target.value);
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <BasicFormLabel variant="body1">
                                Company Name (Optional)
                            </BasicFormLabel>
                            <BasicInput
                                fullWidth
                                placeholder="Enter company name"
                                value={formData.company_name}
                                onChange={(e) => {
                                    handleChangeFormData("company_name", e.target.value);
                                }}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <BasicFormLabel variant="body1">
                                First Name
                            </BasicFormLabel>
                            <BasicInput
                                fullWidth
                                placeholder="Enter first name"
                                value={formData.first_name}
                                onChange={(e) => {
                                    handleChangeFormData("first_name", e.target.value);
                                }}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <BasicFormLabel variant="body1">
                                Last Name
                            </BasicFormLabel>
                            <BasicInput
                                fullWidth
                                placeholder="Enter last name"
                                value={formData.last_name}
                                onChange={(e) => {
                                    handleChangeFormData("last_name", e.target.value);
                                }}
                            />
                        </Grid>

                        {
                            formData.numbers.map((number, index) => (
                                <Grid item xs={12} key={index}>
                                    <Grid item xs={12}>
                                        {
                                            index === 0 && (
                                                <BasicFormLabel variant="body1">
                                                    Phone Number(s)
                                                </BasicFormLabel>
                                            )
                                        }

                                        <Grid container spacing={2}>
                                            <Grid item xs={6} className="mask-numbr-input">
                                                <BasicMaskedInput
                                                    mask={["+", /\d/, " ", "(", /\d/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/,]}
                                                    className="MuiInputBase-input"
                                                    placeholder="Enter phone number"
                                                    guide={true}
                                                    keepCharPositions={true}
                                                    id={"number-input-field" + index}
                                                    onChange={(event) => {
                                                        handleChangePhoneNumber(index, "number", event.target.value);
                                                    }}
                                                    value={number.number}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <DynamicFieldWrapper>
                                                    <BasicSelect
                                                        value={number.number_state}
                                                        onChange={(event) => {
                                                            handleChangePhoneNumber(index, "number_state", event.target.value);
                                                        }}
                                                        options={PHONE_TYPES}
                                                        mapping={{
                                                            label: "label",
                                                            value: "value"
                                                        }}
                                                    />
                                                    {
                                                        index === 0 ? (
                                                            <BasicAddButton2 onClick={addMorePhoneNumber}>
                                                                <AddMoreIcon/>
                                                            </BasicAddButton2>
                                                        ) : (
                                                            <IconButton
                                                                size="small"
                                                                className=""
                                                                style={{marginLeft: "12px"}}
                                                                onClick={() => {
                                                                    removePhoneNumber(index);
                                                                }}
                                                            >
                                                                <DeleteForeverIcon color="error"/>
                                                            </IconButton>
                                                        )
                                                    }
                                                </DynamicFieldWrapper>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))
                        }

                        {
                            formData.emails.map((email, index) => (
                                <Grid item xs={12} key={index}>
                                    <Grid item xs={12}>
                                        {
                                            index === 0 && (
                                                <BasicFormLabel variant="body1">
                                                    Email Address(s)
                                                </BasicFormLabel>
                                            )
                                        }

                                        <DynamicFieldWrapper>
                                            <BasicInput
                                                fullWidth
                                                placeholder="Email Address"
                                                value={email.email}
                                                onChange={(event) => {
                                                    handleChangeEmailAddress(index, "email", event.target.value);
                                                }}
                                            />
                                            {
                                                index === 0 ? (
                                                    <BasicAddButton2 onClick={addMoreEmailAddress}>
                                                        <AddMoreIcon/>
                                                    </BasicAddButton2>
                                                ) : (
                                                    <IconButton
                                                        size="small"
                                                        className=""
                                                        style={{marginLeft: "12px"}}
                                                        onClick={() => {
                                                            removeEmailAddress(index);
                                                        }}
                                                    >
                                                        <DeleteForeverIcon color="error"/>
                                                    </IconButton>
                                                )
                                            }
                                        </DynamicFieldWrapper>
                                    </Grid>
                                </Grid>
                            ))
                        }

                        {
                            showAddress ? (
                                <Grid item xs={12} style={{paddingBottom: 0}}>
                                    <AddressSearchToggleWrapper>
                                        <AddressSearchButton
                                            variant="contained"
                                            onClick={() => {
                                                setShowAddress(false);
                                            }}
                                        >
                                            Address Search
                                        </AddressSearchButton>
                                    </AddressSearchToggleWrapper>
                                </Grid>
                            ) : (
                                <Grid item xs={12}>
                                    <GooglePlacesAutocompleteContainer>
                                        <BasicFormLabel variant="body1">
                                            Address Search
                                        </BasicFormLabel>

                                        <GooglePlacesAutocompleteWrapper>
                                            <GooglePlacesAutocompleteInput>
                                                <GooglePlacesAutocomplete
                                                    apiKey={GOOGLE_API_KEY}
                                                    autoFocus={false}
                                                    autocompletionRequest={{
                                                        componentRestrictions: {
                                                            country: ["us", "ca"],
                                                        },
                                                    }}
                                                    selectProps={{
                                                        value: "",
                                                        onChange: async (e) => {
                                                            await handleAddress(e.value);
                                                        },
                                                        placeholder: <span style={{ color:"#929da9"}}>"Start typing address here…"</span>,
                                                        classNamePrefix: "my-google-places-autocomplete",
                                                    }}
                                                />
                                            </GooglePlacesAutocompleteInput>
                                            <GooglePlacesAutocompleteIconWrapper>
                                                <GooglePlacesAutocompleteIcon>
                                                    {IconList.locationSVG}
                                                </GooglePlacesAutocompleteIcon>
                                            </GooglePlacesAutocompleteIconWrapper>

                                            <AddressManualButton
                                                variant="contained"
                                                onClick={() => {
                                                    setShowAddress(true)
                                                }}
                                            >
                                                Manual
                                            </AddressManualButton>
                                        </GooglePlacesAutocompleteWrapper>
                                    </GooglePlacesAutocompleteContainer>
                                </Grid>
                            )
                        }

                        {
                            showAddress ? (
                                <Fragment>
                                    <Grid item xs={12} style={{paddingTop: "0px"}}>
                                        <BasicFormLabel variant="body1">
                                            Address
                                        </BasicFormLabel>
                                        <BasicInput
                                            fullWidth
                                            placeholder="Enter address"
                                            value={formData.address}
                                            onChange={(event) => {
                                                handleChangeFormData("address", event.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <BasicFormLabel variant="body1">
                                            City
                                        </BasicFormLabel>
                                        <BasicInput
                                            fullWidth
                                            placeholder="Enter city"
                                            value={formData.city}
                                            onChange={(event) => {
                                                handleChangeFormData("city", event.target.value);
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <BasicFormLabel variant="body1">
                                            State
                                        </BasicFormLabel>
                                        <BasicSelect
                                            options={US_STATE_LIST}
                                            mapping={{
                                                label: "label",
                                                value: "value"
                                            }}
                                            value={formData.state}
                                            defaultText="Select state"
                                            onChange={(event) => {
                                                handleChangeFormData("state", event.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <BasicFormLabel variant="body1">
                                            Country
                                        </BasicFormLabel>
                                        <BasicInput
                                            fullWidth
                                            placeholder="Enter country"
                                            value={formData.country}
                                            onChange={(event) => {
                                                handleChangeFormData("country", event.target.value);
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <BasicFormLabel variant="body1">
                                            Zip Code:
                                        </BasicFormLabel>
                                        <BasicInput
                                            fullWidth
                                            placeholder="Enter zip code"
                                            value={formData.zip}
                                            onChange={(event) => {
                                                handleChangeFormData("zip", event.target.value);
                                            }}
                                        />
                                    </Grid>
                                </Fragment>
                            ) : (
                                <Grid item xs={12} style={{paddingTop: 0}}>
                                    <div style={{fontSize: "12px"}}>{makeAddress()}</div>
                                </Grid>
                            )
                        }

                        <Grid item xs={12}>
                            <HeadlineWrapper>
                                <Typography variant="body1">Additional Info</Typography>
                            </HeadlineWrapper>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <BasicFormLabel variant="body1">
                                Birth date
                            </BasicFormLabel>
                            <BasicInput
                                fullWidth
                                type="date"
                                style={{color:`${ !formData.birth_date ? placeholderColor : activeColor}`}}
                                value={formData.birth_date}
                                onChange={(e) => {
                                    handleChangeFormData("birth_date", e.target.value);
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <BasicFormLabel variant="body1">
                                Anniversary date
                            </BasicFormLabel>
                            <DynamicFieldWrapper>
                                <BasicInput
                                    fullWidth
                                    type="date"
                                    style={{color: `${!formData.anniversary_date ? placeholderColor : activeColor}`}}
                                    vlue={formData.anniversary_date}
                                    onChange={(e) => {
                                        handleChangeFormData("anniversary_date", e.target.value);
                                    }}
                                />
                                <BasicAddButton2 onClick={addMoreImportantDate}>
                                    <AddMoreIcon/>
                                </BasicAddButton2>
                            </DynamicFieldWrapper>
                        </Grid>

                        {
                            formData.important_dates.map((important_date, index) => (
                                <Fragment key={index}>
                                    <Grid item xs={12} md={6}>
                                        <DynamicFieldWrapper>
                                            <BasicInput
                                                fullWidth
                                                placeholder="Add a title here"
                                                value={important_date.title}
                                                onChange={(event) => {
                                                    handleChangeImportantDate(index, "title", event.target.value);
                                                }}
                                            />
                                        </DynamicFieldWrapper>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <DynamicFieldWrapper>
                                            <BasicInput
                                                fullWidth
                                                value={important_date.value}
                                                style={{color:`${!important_date.value ?  placeholderColor : activeColor}`}}
                                                type="date"
                                                onChange={(event) => {
                                                    handleChangeImportantDate(index, "value", event.target.value);
                                                }}
                                            />
                                            <IconButton
                                                size="small"
                                                className=""
                                                style={{marginLeft: "12px"}}
                                                onClick={() => {
                                                    removeImportantDate(index);
                                                }}
                                            >
                                                <DeleteForeverIcon color="error"/>
                                            </IconButton>
                                        </DynamicFieldWrapper>
                                    </Grid>
                                </Fragment>
                            ))
                        }

                        <Grid item xs={12}>
                            <ContactCustomFieldGrid>
                                {
                                    customFields &&
                                    Array.isArray(customFields) &&
                                    customFields.map((each, index) => {
                                        return renderEachCustomField(
                                            each,
                                            index,
                                            handleCustomFieldChange,
                                            formData.customFields
                                        );
                                    })
                                }
                            </ContactCustomFieldGrid>
                        </Grid>
                    </Grid>
                </ContactAddModalBody>
                <AddContactFooter>
                    <AddContactSave disabled={isSubmitting} variant="contained" onClick={submitAddContact} disableElevation>
                        {isSubmitting ? "Saving..." : "Save"}
                    </AddContactSave>
                </AddContactFooter>
            </ContactAddModalWrapper>
        </ContactAddModal>
    );
}

const mapStateToProps = (state) => {
    return {
        contact: state.addContactReducer.contact,
        userTagList: state.addContactReducer.userTagListAddContact,
        storedSourceList: state.addContactReducer.sourceListAddContact,
        storedCustomField: state.addContactReducer.userCustomFieldsAddContact,
        assignedContactListInfo: state.addContactReducer.assignedContactListInfo,
        contactListParams: state.addContactReducer.contactListParams,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchUserTagList: (params, callback) => dispatch(getUserTagList(params, callback)),
        storeUserTagList: (params) => dispatch(setUserTagList(params)),
        fetchUserSourceList: (params, callback) => dispatch(getUserSourceList(params, callback)),
        storeUserSourceList: (params) => dispatch(setUserSourceList(params)),
        fetchUserCustomFields: (params, callback) => dispatch(getUserCustomFieldList(params, callback)),
        storeUserCustomFields: (params) => dispatch(setUserCustomFieldList(params)),
        storeUserCustomFieldsNew: (params) => dispatch(storeUserCustomFieldsListNew(params)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactAdd);
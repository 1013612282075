import { Box } from "@material-ui/core";
import React from "react";
import ContactInfo from "./contactInfo/ContactInfo";
import ContactBody from "./contacts/contactBody/ContactBody";
import MoreInfo from "../MoreInfo";
import { RightSideStyle } from "./RightSideStyle";

const RightSide = (props) => {
  const { contactDetails } = props || {};
  const { customScrollBar } = RightSideStyle();
  return (
    <Box>
      <ContactBody
        closeModal={props.closeModal}
        contactDetails={contactDetails}
      />
      <Box sx={{ marginTop: "12px",height: "calc(100vh - 276px)", overflowY: "auto",paddingRight:'5px' }}   className={customScrollBar}>
        <ContactInfo />
        <MoreInfo contactDetails={contactDetails} />
      </Box>
    </Box>
  );
};

export default RightSide;

import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { deleteCookie } from "../../helpers/Cookie";
import Utils from "../../helpers/Utils";
import If from 'if-else-react';

const ProfileMenu = props => {

    let history = useHistory();

    const logout = e => {
        e.preventDefault();
        let userId = Utils.getAccountData('userId');

        deleteCookie(process.env.REACT_APP_ACCESS_TOKEN);
        deleteCookie(process.env.REACT_APP_ACCOUNT_DATA);
        if (localStorage.getItem("global__custom__field__data")) {
            localStorage.removeItem("global__custom__field__data");
        }
        // history.push('/login');
        // window.location.reload();
        const channel = new BroadcastChannel('pypepro-channel');

        // Send a message to other tabs
        channel.postMessage({ "action": "reload-page-" + userId, "link": "/" });
    }

    return (
        <>
            <li className="d-flex align-items-center profile-top-menu-wrapper">
                <div className="profile-picture d-flex justify-content-center align-items-center profile-menu-image-box">
                    <a href="#">
                        <svg width="19" height="20" viewBox="0 0 19 20" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M5.30921 4.93421C5.30921 7.44785 7.35478 9.49342 9.86842 9.49342C12.3821 9.49342 14.4276 7.44785 14.4276 4.93421C14.4276 2.42057 12.3821 0.375 9.86842 0.375C7.35478 0.375 5.30921 2.42057 5.30921 4.93421ZM17.9737 19.625H18.9868V18.6118C18.9868 14.7021 15.8045 11.5197 11.8947 11.5197H7.8421C3.93132 11.5197 0.75 14.7021 0.75 18.6118V19.625H17.9737Z"
                                fill="#133159" />
                        </svg>
                    </a>
                </div>
                <div className="user-profile-info">
                    <a href="#">
                        <h5>{Utils.getAccountData('fullName')}</h5>
                        <p>{Utils.getAccountData('email')}</p>
                    </a>
                </div>
            </li>
            <li>
                <Link to='/user/profile' onClick={() => props.handleClose('profile')}>
                    My Profile
                    <p>Update your personal and company profile, change your password, view billing info and more</p>
                </Link>
            </li>
            <If condition={Utils.getAccountData('userIsOwner')}>
                <li>
                    <Link to='/user/profile/team' onClick={() => props.handleClose('profile')}>
                        My Team
                        <p>Add, delete and manage your users</p>
                    </Link>
                </li>
                <li>
                    <Link to='/user/packages' onClick={() => props.handleClose('profile')}>
                        Package
                        <p>Upgrade or downgrade your existing plan</p>
                    </Link>
                </li>
                <li>
                    <Link to='/billing' onClick={() => props.handleClose('profile')}>Billing <p>Your transaction history.</p></Link>
                </li>
                {
                    Utils.getAccountData("agencyId") === 1 && Utils.getAccountData("userId") !== 13197 &&
                    <li>
                        <Link to='/cancellation-request' onClick={() => props.handleClose('profile')}><span className={"cancel-drop-down-btn"}>Cancel Account</span></Link>
                    </li>
                }
            </If>
            <li><a href="#" onClick={(e) => logout(e)}>log out</a></li>
        </>
    );
}

export default ProfileMenu;
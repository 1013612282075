import React, {useEffect, useState} from "react";
import GlobalEmailSendModule from "../SendComponents/Email";

const EmailSendInitialState = {
    /* props data */
    contactId: null,
    id: null,
    contactDetails: null,
    contactInfo: null,
    from: '',
    sendSuccessCallBack: null,
    primaryEmail: '',
    fetchContactDetails: false,
    emailId : null,
    open:false,
    threadData: null
};
const EmailSendModule = (props) => {
    const [emailSendState, setEmailSendState] = useState(EmailSendInitialState);
    // const [isOpenEmailSendModal, setIsOpenEmailSendModal] = useState(false);

    // window.handleGlobalEmailSendModal = info => {
    //     if (info.open) {
    //         setEmailSendState({
    //             ...emailSendState,
    //             contactId: info.id,
    //             id: info.id,
    //             from: info.from,
    //             sendSuccessCallBack: typeof info.sendSuccessCallBack === 'undefined' ? EmailSendInitialState.sendSuccessCallBack : info.sendSuccessCallBack,
    //             primaryEmail: typeof info.primaryEmail === 'undefined' ? EmailSendInitialState.primaryEmail : info.primaryEmail,
    //             fetchContactDetails: typeof info.fetchContactDetails === 'undefined' ? EmailSendInitialState.fetchContactDetails : info.fetchContactDetails,
    //             contactDetails: typeof info.contactInfo === 'undefined' ? EmailSendInitialState.contactDetails : info.contactInfo,
    //             contactInfo: typeof info.contactInfo === 'undefined' ? EmailSendInitialState.contactInfo : info.contactInfo
    //         });
    //         setIsOpenEmailSendModal(true);
    //     } else {
    //         setIsOpenEmailSendModal(false);
    //         setEmailSendState(EmailSendInitialState);
    //     }
    // }

    useEffect(()=>{

        let info = props.info;
                    setEmailSendState({
                ...emailSendState,
                contactId: info.id,
                id: info.id,
                from: info.from,
                sendSuccessCallBack: typeof info.sendSuccessCallBack === 'undefined' ? EmailSendInitialState.sendSuccessCallBack : info.sendSuccessCallBack,
                primaryEmail: typeof info.primaryEmail === 'undefined' ? EmailSendInitialState.primaryEmail : info.primaryEmail,
                fetchContactDetails: typeof info.fetchContactDetails === 'undefined' ? EmailSendInitialState.fetchContactDetails : info.fetchContactDetails,
                contactDetails: typeof info.contactInfo === 'undefined' ? EmailSendInitialState.contactDetails : info.contactInfo,
                contactInfo: typeof info.contactInfo === 'undefined' ? EmailSendInitialState.contactInfo : info.contactInfo,
                emailId: typeof info.emailId === 'undefined' ? EmailSendInitialState.emailId : info.emailId,
                open:true,
                threadData: info.threadData,
            });
    },[])

    const closeEmailSendModal = () => {
        props.closeModal()
    }

   

    return (
        <React.Fragment>
            {emailSendState.open &&
                <GlobalEmailSendModule
                    open={emailSendState.open}
                    onClose={closeEmailSendModal}
                    {...emailSendState}
                />
            }
        </React.Fragment>
    );
}
export default EmailSendModule;
import React from "react"
import { Typography } from "@material-ui/core"
import icon from "./icon"
import Styles from "./ValidationResultModal.module.css"
import StripedProgress from "./StripedProgress"

const formatPhoneNumber = (phoneNumberString) => {
  if (!phoneNumberString) {
    return "N/A"
  }

  if (phoneNumberString.length < 11) {
    phoneNumberString = "1" + phoneNumberString
  }
  return phoneNumberString
    .replace(/\D+/g, "")
    .replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "+$1 ($2) $3-$4")
}

export const ValidationResultModal = (props) => {
  const [REQUEST_DONE, REQUEST_FAILED] = ["REQUEST_DONE", "REQUEST_FAILED"]
  const [
    VALID,
    INVALID,
    DO_NOT_CALL,
    VERIFIED_OK,
    PENDING,
    TRACED,
    TRACE_FAILED,
  ] = [
    "VALID",
    "INVALID",
    "DO_NOT_CALL",
    "VERIFIED_OK",
    "PENDING",
    "TRACED",
    "TRACE_FAILED",
  ]
  const [SOURCE_CLEAN_DATA, SOURCE_CONTACT, SOURCE_SKIP_TRACE] = [
    "CLEAN_DATA",
    "CONTACT",
    "SKIP_TRACE",
  ]

  const renderStatusChip = (status) => {
    switch (status) {
      case VALID:
        return (
          <span className={`${Styles.statusChip} ${Styles.done}`}>VALID</span>
        )
      case INVALID:
        return (
          <span className={`${Styles.statusChip} ${Styles.failed}`}>
            INVALID
          </span>
        )
      case VERIFIED_OK:
        return (
          <span className={`${Styles.statusChip} ${Styles.done}`}>
            VERIFIED OK
          </span>
        )
      case DO_NOT_CALL:
        return (
          <span className={`${Styles.statusChip} ${Styles.failed}`}>
            DO NOT CALL
          </span>
        )
      case TRACED:
        return (
          <span className={`${Styles.statusChip} ${Styles.done}`}>TRACED</span>
        )
      case TRACE_FAILED:
        return (
          <span className={`${Styles.statusChip} ${Styles.failed}`}>
            TRACE FAILED
          </span>
        )
      default:
        return <span className={`${Styles.statusChip}`}>{status}</span>
    }
  }
 
  const parseData = (source) => {
    return props.data.data.find((i) => i.source === source)
  }

  if (props.submitting) {
    return (
      <div style={{ padding: "10px", width: "100%" }}>
        <StripedProgress
          width={100}
          text={
            "Processing... this may take a few minutes depending on the number of records"
          }
        />
        <div className={Styles.borderedDiv}>
          <span className={`${Styles.topText} ${Styles.textColorDarkBlue}`}>RESULTS</span>
          <div className={Styles.content}>
            <div>
              <div className={`${Styles.svgIcon}`}>{icon.google}</div>
              <Typography variant="p">
                <b>Your result will appear here when ready</b>
              </Typography>
            </div>
          </div>
        </div>
        <div className={Styles.closeButtonDiv}>
          <button className={Styles.closeButton} onClick={props.onClose}>
            Close
          </button>
        </div>
      </div>
    )
  }

  return (
    <div style={{ padding: "10px", width: "100%" }}>
      <div>
        <div className={Styles.bodyWrp}>
          {(props.data["email-lookup-status"] === REQUEST_DONE ||
            props.data["carrier-lookup-status"] === REQUEST_DONE ||
            props.data["dnc-lookup-status"] === REQUEST_DONE ||
            props.data["skip-trace-lookup-status"] === REQUEST_DONE) && (
            <div>
              <h5 className={Styles.successTitle}>Processing: 100% complete</h5>
              <div className={Styles.borderedDivV1}>
                <span className={`${Styles.topTextV1} ${Styles.textColorDarkBlue}`}>RESULTS</span>
               <div>
               <div>
                  {props.data["email-lookup-status"] === REQUEST_DONE &&
                    parseData(props.source) && (
                      <div className={`${Styles.resultShown} ${Styles.textColorDarkBlue}`}>
                        <div style={{display:'flex',alignItems:'center',justifyContent:'center',gap:'16px'}}>
                          <div>
                            <Typography variant="p">
                              <b>Email: {parseData(props.source).email}</b>
                            </Typography>
                          </div>

                          <div className={Styles.resultShown} style={{paddingTop:'0px'}}>
                            <Typography>
                              {renderStatusChip(
                                parseData(props.source)[
                                  "email-validation-status"
                                ]
                              )}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
                <div>
                  {props.data["carrier-lookup-status"] === REQUEST_DONE &&
                    parseData(props.source) && (
                      <div className={`${Styles.resultShown} ${Styles.textColorDarkBlue}`}>
                        <div style={{display:'flex',alignItems:'center',justifyContent:'center',gap:'16px'}}>
                          <div>
                            <Typography variant="p">
                              <b>
                                Number:
                                {formatPhoneNumber(
                                    parseData(props.source).number
                                )}
                              </b>
                            </Typography>
                          </div>
                          <div className={`${Styles.resultShown} ${Styles.textColorDarkBlue}`} style={{paddingTop:0}}>
                            <Typography>
                              {" "}
                              {renderStatusChip(
                                parseData(props.source)[
                                  "number-validation-status"
                                ]
                              )}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              
                <div>
                  {props.data["dnc-lookup-status"] === REQUEST_DONE &&
                    parseData(props.source) && (
                      <div className={`${Styles.resultShown} ${Styles.textColorDarkBlue}`}>
                        <div style={{display:'flex',gap:'16px',}}>
                          <div>
                            <Typography variant="p">
                              <b>
                                DNC Status: {formatPhoneNumber(
                                    parseData(props.source).number
                                )}
                              </b>
                            </Typography>
                          </div>
                          <div className={`${Styles.resultShown} ${Styles.textColorDarkBlue}`} style={{paddingTop:0}}>
                            {renderStatusChip(
                              parseData(props.source)["dnc-state"]
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                </div>
                <div>
                  {props.data["skip-trace-lookup-status"] === REQUEST_DONE &&
                    parseData(props.source) && (
                      <div className={`${Styles.resultShown} ${Styles.textColorDarkBlue}`}>
                          <Typography><b>SkipTrace:</b></Typography>
                          <Typography>
                            <b>SkipTrace successful for the contact address</b>
                          </Typography> 
                      </div>
                    )}
                </div>
              </div>
              </div>
            </div>
          )}
          <div>
            {(props.data["email-lookup-status"] === REQUEST_FAILED ||
              props.data["carrier-lookup-status"] === REQUEST_FAILED ||
              props.data["dnc-lookup-status"] === REQUEST_FAILED ||
              props.data["skip-trace-lookup-status"] === REQUEST_FAILED) && (
              <div>
                <h5 className={Styles.failedTitle}>Failed</h5>
                <div className={Styles.borderedDivV1}>
                  <span className={`${Styles.topTextV1} ${Styles.textColorDarkBlue}`}>RESULTS</span>
                  <div>
                    {props.data["email-lookup-status"] === REQUEST_FAILED &&
                      parseData(props.source) &&
                      parseData(props.source)["email-validation-status"] && (
                        <div className={`${Styles.resultShown} ${Styles.textColorDarkBlue}`}>
                          <div>
                            <Typography variant="p">
                              <b>
                                Email:{parseData(props.source).email} (
                                {props.data.error["email-lookup-error"]
                                    ? props.data.error["email-lookup-error"]
                                    : "Email lookup failed"}
                                )
                              </b>
                            </Typography>
                          </div>
                        </div>
                      )}

                    {props.data["carrier-lookup-status"] === REQUEST_FAILED &&
                      parseData(props.source) && (
                        <div className={`${Styles.resultShown} ${Styles.textColorDarkBlue}`} >
                          <Typography variant="p">
                            <b>
                              Number:{" "}
                              {formatPhoneNumber(parseData(props.source).number)}{" "}
                              (
                              {props.data.error["carrier-lookup-error"]
                                  ? props.data.error["carrier-lookup-error"]
                                  : "Number lookup failed"}
                              )
                            </b>
                          </Typography>
                        </div>
                      )}

                    {props.data["dnc-lookup-status"] === REQUEST_FAILED &&
                      parseData(props.source) && (
                        <div className={`${Styles.resultShown} ${Styles.textColorDarkBlue}`}>
                          <Typography variant="p">
                            <b>
                              DNC Status: {" "}
                              {formatPhoneNumber(parseData(props.source).number)}{" "}
                              (
                              {props.data.error["dnc-lookup-error"]
                                  ? props.data.error["dnc-lookup-error"]
                                  : "Do-not-call status check failed"}
                              )
                            </b>
                          </Typography>
                        </div>
                      )}

                    {props.data["skip-trace-lookup-status"] ===
                      REQUEST_FAILED && (
                        <div className={`${Styles.resultShown} ${Styles.textColorDarkBlue}`}>
                        <Typography variant="p">
                         <b>
                           SkipTrace:
                           {props.data.error["skip-trace-lookup-error"]
                               ? props.data.error["skip-trace-lookup-error"]
                               : "SkipTrace failed"}
                         </b>
                        </Typography>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={Styles.closeButtonDiv}>
        <button className={Styles.closeButton} onClick={props.onClose}>
          Close
        </button>
      </div>
    </div>
  )
}

import {useContext, useRef} from 'react';
import Personalized from '../components/personalized/Personalized';
import { updateSubject } from '../reducers/Action';
import { EmailSendContext } from '../reducers/GlobalReduers';
import LinkFile from "../LinkFile";
// import GlobalCustomFieldGrouped from "../../../GlobalCustomFieldGrouped/Index";

const Subject = () => {
    const {states, dispatch} = useContext(EmailSendContext)
    const subjectInputRef = useRef(null)

    const hanldeSubjectChange = (event) => {
        dispatch(updateSubject({
            subject: event.target.value,
            validSubject: false,
        }))
    }

    const handleSubjectPersonalize = (value) => {
        let start = subjectInputRef.current.selectionStart;
        let end = subjectInputRef.current.selectionEnd;
        let text = subjectInputRef.current.value;
        let before = text.substring(0, start);
        let after = text.substring(end, text.length);
        let newText = (before + value + after);
        dispatch(updateSubject({
            subject: newText,
            validSubject: false,
        }))
        setTimeout(() => {
            if(subjectInputRef !== undefined){
                subjectInputRef.current.selectionStart = subjectInputRef.current.selectionEnd = start + value.length;
                subjectInputRef.current.focus();
            }
        }, 100);
    }

    return(
        <div className="g-email-send-subject-content email_send_row">
			<h3 className="g-from-email-text">Subject: </h3>
            <div className="g-subject">
                {states.threadData?.message_subject && <div className="overlay-disabled"></div>}
                <input type="text" className="-g-subject-input" placeholder="Enter mail subject" value={states.subject} onChange={hanldeSubjectChange} ref={subjectInputRef} disabled={states.threadData?.message_subject}/>
                <LinkFile.GlobalCustomFieldGrouped className={"send_module__global_custom__field"} labelText={"Personalized"} handleSelect={handleSubjectPersonalize}/>
            </div>
            {(states.validSubject && states.subject == '') &&
                <span className="g-subject-error">Subject is required!</span>
            }
        </div>
    )
}
export default Subject;



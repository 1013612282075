import React, {useState} from "react";
import {Helmet} from "react-helmet";
import {useLocation} from "react-router-dom";
import useBulkEmailUnsubscribe from "./useBulkEmailUnsubscribe";
import {unsubscribeBulkEmail} from "../../api/bulkEmailApi";

const Unsubscribe = () => {
    const classes = useBulkEmailUnsubscribe();
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const recipientId = params.get("recipient_id");
    const emailId = params.get("email_id");
    const grantId = params.get("grant_id");
    const sequenceId = params.get("sequence_id");
    const sequenceVariationId = params.get("sequence_variation_id");
    const [submitting, setSubmitting] = useState(false);

    const handleUnsubscribeBulkEmail = async () => {
        try {
            setSubmitting(true);
            const resp = await unsubscribeBulkEmail({
                campaignId: emailId,
                contactId: recipientId,
                grantId: grantId ? grantId : null,
                sequenceId: sequenceId ? sequenceId : null,
                sequenceVariationId: sequenceVariationId ? sequenceVariationId : null
            });

            if (resp && resp.success){
                window.open("/bulk-email/unsubscribe?thank_you=true", "_self");
            }else {
                if (window.showNotification){
                    window.showNotification("error", "Failed to unsubscribe");
                }
            }
            console.log(resp);
        }catch (err){
            console.log(err);
            if (window.showNotification){
                window.showNotification("error", "Failed to unsubscribe");
            }
        }finally {
            setSubmitting(false);
        }
    }

    return (
        <div className={classes.page}>
            <Helmet>
                <title>Unsubscribe</title>
            </Helmet>
            <div className={classes.container}>
                <div className={classes.innerContainer}>
                    <img src="https://d24gujj67p1uc1.cloudfront.net/assets/bulk-email/images/releases/1.0.1/message-00909.svg" alt="Message image"/>

                    <div className={classes.bottom}>
                        <h2 className={classes.title}>Do you want to unsubscribe?</h2>
                        <p className={classes.subtitle}>If you unsubscribe, you will stop receiving emails from us.</p>
                        <div className={classes.buttons}>
                            <button disabled={submitting} id="unsubscribe" onClick={handleUnsubscribeBulkEmail}>Unsubscribe</button>
                            <button id="cancel" onClick={()=>{ window.open("/bulk-email/unsubscribe?thank_you=true", "_self"); }}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Unsubscribe;
import React, {useState, useEffect, useRef} from "react";
import { connect } from "react-redux";
import { getVirtualNumbers } from "./inboxAction";
import {sendMessage} from "../../../api/contactApi";
import SavedReply from "./common/SavedReply";
import CoreConstants from "./constants/CoreConstants";
import If from "if-else-react";
import { DropdownHelper } from "../../addContact/DropdownHelper";
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import SwitchC from "./globals/Switch/Switch";
import HelpIcon from '@material-ui/icons/Help';
import BootstrapTooltip from "../../globals/BootstrapTooltip";
import Utils from "../Helpers/Utils";
import GlobalCustomFieldGrouped from "./globals/GlobalCustomFieldGrouped/Index";
import ModalComponent from "./common/modal/ModalComponent";
import UnicodeAlert from "./common/alert-popup/UnicodeAlert";
import {getUserCampaignRegistrationStatusApi} from "../../../api/contactApi";

const App = (props) => {
    const [contact, setContact] = useState(props.selectedContact);
    const [selectedVirtualNumber, setSelectedVirtualNumber] = useState('');
    const [virtualNumbers, setVirtualNumbers] = useState(props.virtualNumbers);
    const [conversationText, setConversationText] = useState('');
    const [savedReplyModal, setSavedReplyModal] = useState(false);
    const textInput = useRef(null);

    const [sending, setSending] = useState(false)

    const [selectMMS, setSelectMMS] = useState(false)
    const [saveAsTemplate, setSaveAsTemplate] = useState(false)

    const [mmsFromQuickReply, setMmsFromQuickReply] = useState(false)
    const [mmsUrl, setMmsUrl] = useState("")

    /* for adding unsubscribed message */
    const [addUnsubscriedText, setAddUnsubscriedText] = useState(false);  
    
    const [isShowAlert, setIsShowAlert] = useState(false)

    useEffect(() => {
        if (props.virtualNumbers.length === 0) {
            props.getVirtualNumbers();
        } else {
            
            setSelectedVirtualNumber(props.virtualNumbers[0].id)
            try{
                if(props.lastConnectedVirtualNumberId!= null && props.virtualNumbers !== undefined && props.virtualNumbers != null && Array.isArray(props.virtualNumbers)){
                    let lengthss = props.virtualNumbers.length;
    
                    for(let i=0;i<lengthss;i++){
                        if(props.virtualNumbers[i].id == props.lastConnectedVirtualNumberId){ // eslint-disable-line eqeqeq
                            setSelectedVirtualNumber(props.lastConnectedVirtualNumberId)
                            break;
                        }
                    }
                }
            }
            catch(e){
                console.log('virtual number select error')
            }            

            setVirtualNumbers(props.virtualNumbers);
        }
    }, [props.virtualNumbers]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        try{
            if(props.lastConnectedVirtualNumberId != null && virtualNumbers !== undefined && virtualNumbers != null && Array.isArray(virtualNumbers)){
                let lengthss = virtualNumbers.length;
    
                for(let i=0;i<lengthss;i++){
                    if(virtualNumbers[i].id == props.lastConnectedVirtualNumberId){ // eslint-disable-line eqeqeq
                        setSelectedVirtualNumber(props.lastConnectedVirtualNumberId)
                        break;
                    }
                }
            }
        }
        catch(e){
            console.log('virtual number select error')
        }
    }, [props.lastConnectedVirtualNumberId]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setContact(props.selectedContact);
    }, [props.selectedContact]);

    const handlePersonalize = (event) => {
        let start = textInput.current.selectionStart;
        let end = textInput.current.selectionEnd;
        let text = textInput.current.value;
        let before = text.substring(0, start);
        let after = text.substring(end, text.length);
        let newText = (before + event + after);

        setConversationText(newText);

        setTimeout(() => {
            textInput.current.selectionStart = textInput.current.selectionEnd = start + event.length;
            textInput.current.focus();
        }, 500);
    }

    const sendText = (event) => {
        event.preventDefault();
        setIsShowAlert(false)
        if(sending){
            return
        }
        if(conversationText === ''){
            if(window.showNotification !== undefined){
                window.showNotification("WARNING", "Message is required");
            }
            return
        }

        setSending(true)
        let formData = new FormData();
        formData.append('contact_id', contact.id);
        formData.append('origin', 3);
        if(addUnsubscriedText){
            formData.append('message', (conversationText+"\r\nReply STOP to unsubscribe."));
        }
        else{
            formData.append('message', conversationText);
        }
        formData.append('messageLevel', 'reply');
        formData.append('save_as_template', saveAsTemplate);
        formData.append('saveTemplate', saveAsTemplate);
        formData.append('virtual_number_id', selectedVirtualNumber);
        if(mmsFromQuickReply){
            formData.append('file_path', mmsUrl);
            formData.append('message_type', 2);
        }
        else{
            if (document.querySelector('#mms-file').files[0]) {
                formData.append('qqfile', document.querySelector('#mms-file').files[0]);
            }
        }

        sendMessage(formData)
        .then(response => {
            let virtualNumber = ''
            setSending(false)
            if(response.data.status === 'success'){
                let length = virtualNumbers.length;
                for(let j=0;j<length; j++){
                    if(virtualNumbers[j].id === selectedVirtualNumber){
                        virtualNumber = virtualNumbers[j].virtual_number
                        break;
                    }
                }
                let virtualNumberInf0= {id: selectedVirtualNumber, virtual_number: virtualNumber}
                let fullData = response.data.fullData;
                fullData['virtual_number'] = virtualNumberInf0
                window.appendConversation(fullData)
                if(props.closeTab !== undefined){
                    props.closeTab()
                }
            }
            else{
                if(response.data.html ===  "The contact have no phone number."){
                    if(window.showNotification !== undefined){
                        window.showNotification("ERROR", "The contact have no phone number.")
                    }
                }
                else if(response.data.html ===  "Contact is blocked"){
                    if(window.showNotification !== undefined){
                        window.showNotification("ERROR", "Contact is blocked")
                    }
                }
                else if(response.data.html ===  "Credit Not Available"){
                    if(window.showNotification !== undefined){
                        window.showNotification("ERROR", "Credit Not Available")
                    }
                }
                else if(response.data.html ===  "[HTTP 400] Unable to create record: Attempt to send to unsubscribed recipient"){
                    if(window.showNotification !== undefined){
                        window.showNotification("ERROR", "Attempt to send to unsubscribed recipient !")
                    }
                }
                else{
                    if(window.showNotification !== undefined){
                        window.showNotification("ERROR", "Something went wrong. Try again later")
                    }
                }
            }
        }).catch(error => {

        })
    }

    const selectImage = (e) => {
        e.preventDefault();
        let inputField = document.getElementById('mms-file');
        inputField.click();
    }

    const changeMmsAttachments = (e) => {
        try{
            let file = document.querySelector('#mms-file').files[0];
            let file_size =  Math.round((file.size / 1024)) ;
            if(file_size > 0){
                if(file_size > 5120){
                    if(window.showNotification !== undefined){
                        window.showNotification("WARNING", "File is too large. Max file size 5MB");
                    }
                    setSelectMMS(false)
                    setMmsFromQuickReply(false)
                    setMmsUrl("")
                    e.target.value = '';
                }
                else{
                    let preview = document.getElementById("resize_upload_img");
                    if (e.target.value) {
                        let reader = new FileReader();
                        reader.addEventListener("load", function () {
                            preview.src = reader.result;
                            document.getElementById('preview-holder').classList.remove("d-none");
                        }, false);
                        if (file) {
                            reader.readAsDataURL(file);
                        }
                        setSelectMMS(true)
                    }
                    setMmsFromQuickReply(false)
                    setMmsUrl("")
                }
            }
        }catch(error){
            console.log("file upload error")
        }
    }

    const removeAttachments = () => {
        let mmsFile=document.getElementById("mms-file");
        let imageFile = document.getElementById("resize_upload_img");
        let mmsSelector = document.getElementById('preview-holder');
        if(mmsFile){
            mmsFile.value = "";
        }
        if(imageFile){
            imageFile.src='';
        }
        if(mmsSelector){
            mmsSelector.classList.add("d-none");
        }
        setSelectMMS(false)
    }

    const removeMms = () => {
        setMmsFromQuickReply(false)
        setMmsUrl("")
    }

    const useMessageTemplate = data => {
        if(data.type === 5){
            setMmsFromQuickReply(true)
            setMmsUrl(data.video_file_url)
            removeAttachments()
        }else{
            setMmsFromQuickReply(false)
            setMmsUrl("")
        }
        setConversationText(data.message);
        setSavedReplyModal(false);
    }

    const renderUnsubTooltip = () => {
        let whole_message = "";
        if(conversationText.length > 0){
            whole_message = conversationText + "\r\nReply STOP to unsubscribe.";
        }
        else{
            whole_message = "Reply STOP to unsubscribe.";
        }
        if(!addUnsubscriedText){
            whole_message = conversationText;
        }
        let countResult = Utils.countSms(whole_message);
        return(
            <div>
                {addUnsubscriedText &&
                <div>
                    <p style={{padding: 0, margin:0, underLine: '1px solid', fontSize:'12px'}}><b>Receiver Text: </b></p>
                    {conversationText.length > 0 ?
                        <i>{conversationText}<br/>Reply STOP to unsubscribe.</i>
                    :
                        <i>Reply STOP to unsubscribe.</i>
                    }
                </div>
                }
                {!addUnsubscriedText &&
                <div>
                    <p style={{padding: 0, margin:0, underLine: '1px solid', fontSize:'12px'}}><b>Receiver Text: </b></p>
                    <i>{conversationText}</i>
                </div>
                }
                <br/>
                <div><span>Total SMS: {countResult.length}/{countResult.sms}</span></div>
                <span style={{color: 'white'}}>If turned on, “Reply STOP to unsubscribe” will appear at the end of all your text messages.</span>
            </div>
        );
    }

     /* for unicode alert */
     const handleEdit = () => {
        setIsShowAlert(false)
    }
    const handleSend = async (e, bypass=false) => {

         var shouldNotGo = true;

        if (!bypass){
            if(Utils.getAccountData("campaignRegistrationAvailable") === "TRUE"){
                shouldNotGo = false;
            }else {
                try{
                    let res = await getUserCampaignRegistrationStatusApi()
                    res = res.data;
                    if(res.success){
                        if (res.campaignRegistrationStatus === "TRUE"){
                            shouldNotGo = false;
                        }else {
                            window.globalSmsRestriction(true, {
                                callback: (res)=>{
                                    if (res.sendWithRisk) {
                                        handleSend(e, true);
                                        shouldNotGo = false;
                                    }
                                }
                            });
                            shouldNotGo = true;
                        }
                    }

                }catch (err){
                    shouldNotGo = false;
                    console.log(err);
                }
            }
        }else {
            shouldNotGo = false;
        }

        if (shouldNotGo){
            return;
        }

        /* check message */
        if(conversationText.trim() === ""){
            if(window.showNotification !== undefined){
                window.showNotification("WARNING", "Message is required");
            }
            return;
        }
        /* check unicode */
        const hasUniCode = checkHasUniCode(conversationText.trim())
        if(hasUniCode){
            setIsShowAlert(true)
        }
        else{
            sendText(e)
        }
    }
    const checkHasUniCode = (text) => {
        // eslint-disable-next-line no-control-regex
        if (!text.length) return false;
        if (text.charCodeAt(0) > 255) return true;
        for (var i = 0, n = text.length; i < n; i++) {
            if (text.charCodeAt( i ) > 255) { 
                return true;
            }
            // eslint-disable-next-line no-control-regex
            let regex = /[^\u0000-\u00FF]/;
            const codePoint =  text.codePointAt(i);
            const hexCodePoint = codePoint.toString(16);
            let testing = toUTF16(hexCodePoint)
            if(regex.test(testing)){
                return true
            }
        }
        return false;
    }
    function toUTF16(codePoint) {
        var TEN_BITS = parseInt('1111111111', 2);
        function u(codeUnit) {
            return '\\u'+codeUnit.toString(16).toUpperCase();
        }
    
        if (codePoint <= 0xFFFF) {
            return u(codePoint);
        }
        codePoint -= 0x10000;
    
        // Shift right to get to most significant 10 bits
        var leadingSurrogate = 0xD800 | (codePoint >> 10);
    
        // Mask to get least significant 10 bits
        var trailingSurrogate = 0xDC00 | (codePoint & TEN_BITS);
    
        return u(leadingSurrogate) + u(trailingSurrogate);
        // return u(leadingSurrogate);
    }

    return (
      <div id="textTab" className="col s12 active inbox__text__msg__wr">
        <div className="chat__bottom_wrapper">
          <div className="catagory-area">
            <div className="mb-0 row">
              <div className="">
                <div className="inbox__text__msg__area__wr">
                  <div className="inbox__all__btn__group">
                    <div className="inbox__all__btn__group_single__alt">
                      <GlobalCustomFieldGrouped
                        className="inbox__text__global__personalized"
                        handleSelect={handlePersonalize}
                      />
                    </div>
                    <div
                      className="inbox__all__btn__group_single"
                      onClick={() => {
                        setSavedReplyModal(true);
                      }}
                    >
                      <svg
                        width="10"
                        height="15"
                        viewBox="0 0 10 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M0 0H10V15L5 11.9118L0 15V0Z" fill="#133159" />
                      </svg>
                      <span>Quick Reply</span>
                    </div>
                    <If condition={savedReplyModal}>
                      <SavedReply
                        openSavedReplyModal={savedReplyModal}
                        setOpenSavedReplyModal={setSavedReplyModal}
                        useMessageTemplate={useMessageTemplate}
                        templateType={CoreConstants.MESSAGE_TEMPLATE.SMS}
                      />
                    </If>
                    <div
                      className="inbox__all__btn__group_single"
                      onClick={(e) => selectImage(e)}
                    >
                      <svg
                        className=""
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="18"
                        viewBox="0 0 15 20"
                      >
                        <path
                          fill="#133159"
                          d="M13.833 13H2.167A1.162 1.162 0 0 1 1 11.842V3.158C1 2.518 1.522 2 2.167 2h11.666C14.478 2 15 2.518 15 3.158v8.684c0 .64-.522 1.158-1.167 1.158zM2.4 3a.4.4 0 0 0-.4.4v8.2c0 .22.18.4.4.4h11.2a.4.4 0 0 0 .4-.4V3.4a.4.4 0 0 0-.4-.4H2.4zm7.625 2.005a1 1 0 1 1 2 0 1 1 0 0 1-2 0zm4.49 6.495a.503.503 0 0 1-.311-.106l-3.305-2.601-1.31 1.03.41.365a.43.43 0 0 1 .014.64.51.51 0 0 1-.684.014L5.68 7.594l-3.845 3.765a.509.509 0 0 1-.686.015.432.432 0 0 1-.016-.638l4.18-4.093a.497.497 0 0 1 .34-.141.445.445 0 0 1 .345.124l2.886 2.57 1.703-1.34a.51.51 0 0 1 .624 0l3.617 2.847c.204.16.23.446.058.636a.5.5 0 0 1-.37.161z"
                        ></path>
                      </svg>

                      <span>Attachment</span>
                      <span className="inbox__max__file__text">Only Image</span>
                    </div>
                    <input
                      onChange={(e) => changeMmsAttachments(e)}
                      type="file"
                      accept="image/png, image/gif, image/jpeg"
                      className="d-none"
                      id="mms-file"
                    />
                  </div>
                  <div className="inbox__tiny__mce__wr">
                    <div
                      className={`inbox__tiny__mce__container ${
                        selectMMS || mmsFromQuickReply ? "multimedia" : ""
                      }`}
                    >
                      <textarea
                        className="inbox__tiny__text__area"
                        placeholder="Type a message"
                        id="basic-example-text"
                        name="conversationText"
                        onChange={(event) =>
                          setConversationText(event.target.value)
                        }
                        ref={textInput}
                        value={conversationText}
                      />
                    </div>
                    <div
                      className={`inbox__tiny__mce__upload__images inbox__upload__scroll__bar  ${
                        selectMMS ? "" : "d-none"
                      }`}
                    >
                      <div
                        className={`d-none single__inbox__tiny__image`}
                        id="preview-holder"
                      >
                        <img src="" alt="" id="resize_upload_img" />
                        <i
                          onClick={removeAttachments}
                          className="material-icons close"
                        >
                          clear
                        </i>
                      </div>
                    </div>

                    <div
                      className={`inbox__tiny__mce__upload__images inbox__upload__scroll__bar  ${
                        mmsFromQuickReply ? "" : "d-none"
                      }`}
                    >
                      <div
                        className="single__inbox__tiny__image"
                        id="preview-holder"
                      >
                        <img src={mmsUrl} alt="" id="resize_upload_img" />
                        <i onClick={removeMms} className="material-icons close">
                          clear
                        </i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="send-button-area">
            <div className="mb-0 row d-flex justify-content-between">
              <div className="select-wrapper">
                <DropdownHelper
                  datalist={virtualNumbers}
                  noneFieldRequired={true}
                  noneFieldTitle="Select Virtual Number"
                  className=""
                  mapping={{ title: "virtual_number" }}
                  menuItemClassName=""
                  updateSelectedData={setSelectedVirtualNumber}
                  selectedValue={selectedVirtualNumber}
                  updateDatatable={() => console.log("change")}
                  dropdownHeader={"ownerChange"}
                  parentDivClassName="ppc__select_owner"
                />
                <div
                  className="ismss_footer__wr"
                  style={{
                    padding: "10px 10px 10px 8px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <FormControl component="fieldset">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={saveAsTemplate}
                          onChange={() => setSaveAsTemplate(!saveAsTemplate)}
                          name="save-as-template"
                        />
                      }
                      label="Save as quick reply"
                    />
                  </FormControl>

                  {/* for adding unsubscribed message */}
                  <div
                    className="add__unsubscried__text"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <span>
                      <small>
                        <i>Reply STOP to unsubscribe</i>
                      </small>
                    </span>
                    <SwitchC
                      status={addUnsubscriedText}
                      brandColor={"#27AE60"}
                      onChange={(value) => setAddUnsubscriedText(value)}
                      onText={""}
                      offText={""}
                      minWidth40={true}
                    />
                    <BootstrapTooltip
                      arrow
                      placement={"top"}
                      title={renderUnsubTooltip()}
                    >
                      <HelpIcon fontSize="small" />
                    </BootstrapTooltip>
                  </div>
                </div>
              </div>

              <div className="flex1">
                <div className="send-message-btn right p-relative">
                  <a href="#!" onClick={(event) => handleSend(event)}>
                    <i className="material-icons left">near_me</i>
                    {sending ? "Sending" : "Send"}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ModalComponent
          isShow={isShowAlert}
          component={
            <UnicodeAlert handleEdit={handleEdit} handleSend={sendText} />
          }
          blockBackdrop={true}
          style={{
            width: "590px",
            height: "390px",
          }}
        />
      </div>
    );
}

const mapStateToProps = state => {
    return {
        virtualNumbers: state.inboxReducer.virtualNumbers,
        personalizes: state.inboxReducer.personalizes,
        selectedContact: state.inboxReducer.selectedContact,
        lastConnectedVirtualNumberId: state.inboxReducer.lastConnectedVirtualNumberId
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getVirtualNumbers: (params) => dispatch(getVirtualNumbers(params)),
    };
}

const Text = connect(mapStateToProps, mapDispatchToProps)(App);

export default Text;
import React from "react";
import * as ACTION from "./constants/inboxActionTypes";


export const storeConversation = payload => ({
    type: ACTION.STORE_CONVERSATION,
    payload: payload
});

export const storeLastContactedNumberId = payload => ({
    type: ACTION.UPDATE_LAST_CONTACTED_NUMBER,
    payload: payload
})

export const updateSelectedContact = payload => ({
    type: ACTION.UPDATE_SELECTED_CONTACT,
    payload: payload
})

export const addSelectedContact = payload => ({
    type: ACTION.ADD_SELECTED_CONTACT,
    payload: payload
});


export const getVirtualNumbers = payload => ({
    type: ACTION.FETCH_VIRTUAL_NUMBERS,
    payload: payload
});

export const getCustomFieldData = () => ({
    type: ACTION.FETCH_CUSTOM_FIELD,
    payload: {}
});

export const setUser = payload => ({
    type: ACTION.SET_USER,
    payload: payload
});

export const markAsImportant = (payload, callback) => ({
    type: ACTION.MARK_AS_IMPORTANT,
    payload: {payload, callback}
});

export const markAsUnread = (payload, callback) => ({
    type: ACTION.MARK_AS_UNREAD,
    payload: {payload, callback}
});

export const deleteLead = (payload, callback) => ({
    type: ACTION.DELETE_CONTACT,
    payload: {payload, callback}
});

export const archiveLead = (payload, callback) => ({
    type: ACTION.ARCHIVE_CONTACT,
    payload: {payload, callback}
});

export const moveToInboxFromArchive = (payload, callback) => ({
    type: ACTION.MOVE_FROM_ARCHIVE,
    payload: {payload, callback}
});

export const getMessageTemplates = payload => ({
    type:ACTION.FETCH_MESSAGE_TEMPLATES,
    payload: payload
});

export const getCategoryList = (payload, callback) =>({
    type: ACTION.GET_MAIL_CATEGORY,
    payload: {payload, callback}
})

export const CreateCardTemplate = (payload,callback)=>({
    type: ACTION.CREATE_POSTCARD_TEMPLATE,
    payload: {payload, callback}
})
export const updateBottomBoxVisibility = (payload)=>({
    type: ACTION.UPDATE_BOTTOM_BOX,
    payload: payload
})
export const toggleRightSidebar = ()=>({
    type: ACTION.TOGGLE_RIGHT_SIDEBAR,
    payload: {}
})
export const changeTabInBottomBox = (payload)=>({
    type: ACTION.CHANGED_TAB_IN_BOTTOM_BOX,
    payload: payload
})

export const updateSelectedTabLeftSide = payload => ({
    type: ACTION.SELECTED_TAB_LEFT_SIDE,
    payload: payload
})

export const updateInboxUnreadContactInfo = payload => ({
    type: ACTION.UPDATE_INBOX_UNREAD_CONTACT,
    payload: payload
})

export const    setContactIdFromDashboard = payload => ({
    type: ACTION.SET_CONTACT_ID_FROM_DASHBOARD,
    payload: payload
})

/* for inbox middle part */
export const handleShowSendOption = payload => ({
    type: ACTION.HANDLE_SEND_VIEW_FLAG,
    payload: payload
})

export const updateActiveSendOption = payload => ({
    type: ACTION.UPDATE_ACTIVE_SEND_OPTION,
    payload: payload
})


export const updateMultipleData = (payload)=>({
    type: ACTION.UPDATE_MULTIPLE_DATA,
    payload: payload
});

export const fetchContactAdditionalContacts = (payload)=>({
    type: ACTION.FETCH_CONTACT_ADDITIONAL_CONTACTS,
    payload: payload
});


import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

export const EachTag = props => {
    let isChecked = props.selectedTags.includes(props.item.id)
    // let user = props.subUserList.find((user) => user.id === props.item.user_id);
    return(
        <div className="g-each-tag-content">
            <div className="g-selected-tag-each" key={props.key} onClick={() => props.onCheckChange(props.item.id,props.item.name)}>
                {isChecked ? <CheckBoxIcon style={{color:'#666dfb'}} /> : <CheckBoxOutlineBlankIcon style={{color:'#666dfb'}} />}
                <span>{props.item.name}
                    {/*&nbsp;- &nbsp;*/}
                    {/*<small><code>{user ? user.full_name : 'Unknown User'}</code></small>*/}
                </span>
            </div>
        </div>
    )
}
import React, { useState, useEffect } from "react";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Box } from '@material-ui/core'
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { IconList } from "../../../icon/IconList.js";
import { ContactsStyles } from "../ContactsStyles";
import BootstrapTooltip from "../../../../globals/BootstrapTooltip";
import VideoCallIcon from '@material-ui/icons/VideoCall';
import NoteComponent from "../../../../GlobalContactDetails/moreInfo/noteNew/NoteComponent.js";
import NoteAddOutlined from '@material-ui/icons/NoteAddOutlined.js';
import { getTimeline } from "../../../redux/contactApi.js";
import {
    removeBulkContact,
    getUserCampaignRegistrationStatusApi,
} from '../../../../../api/contactApi';
import Utils from "../../../../../helpers/Utils";
import dncNoCall from "../../../common/dncNoCall";
import Helper from "../../../Helper";


const GIFT = 8;

const thanksIoActive = (Utils.getAccountData('customMenu')['63'] && Utils.getAccountData('customMenu')['63']
    && Utils.getAccountData('customMenu')['63'] !== null && Utils.getAccountData('customMenu')['63'] !== "") ? true : false

const zenDirectActive = (Utils.getAccountData('customMenu')['61'] && Utils.getAccountData('customMenu')['61']
    && Utils.getAccountData('customMenu')['61'] !== null && Utils.getAccountData('customMenu')['61'] !== "") ? true : false


const ContactActions = (props) => {
    const [mailOptionVisible, setMailOptionVisible] = useState(false);
    const [lastCommunicationMessage, setLastCommunicationMessage] = useState('');
    const [inputList, setInputList] = useState([]);
    const [toggle, setToggle] = useState(false);
    const [notes, setNotes] = useState([]);
    const [showAddNoteModal, isShowAddNoteModal] = useState(false);
    const [editData, setEditData] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [editIndex, setEditIndex] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
            setEditData(null);
            setIsLoading(true);
            setIsEdit(false);
            setEditIndex(null);        
    }, []);
    const toggleAddNoteModal = (afterEdit = false) => {
        isShowAddNoteModal(!showAddNoteModal);
        if (isEdit && !afterEdit) {
            setIsEdit(false);
            setEditData(false);
            setEditIndex(null);
        }
    };
    const storeEditData = (data, index) => {
        setEditData(data);
        if (data === null) {
            setIsEdit(false);
            setEditIndex(null);
        } else {
            setIsEdit(true);
            setEditIndex(index);
            isShowAddNoteModal(true);
        }
    };
    // for video send
    const [activeTab, setActiveTab] = useState(0);
    const history = useHistory();

    const { actionIconButton } = ContactsStyles();

    useEffect(() => {
        getLastCommunicationInfo();
    }, [props.contact]);

    const getContactEmail = () => {
        let contact = props.contact
        if (contact?.email !== undefined && contact.email !== null) {
            return contact.email
        }
        return ""
    }

    const getLastCommunicationInfo = () => {
        setLastCommunicationMessage(props.contact?.contact_activity?.local_last_communication?.for_human
            ? props.contact.contact_activity.local_last_communication.for_human : "No conversation yet!")
    }

    const sendSMSModal = () => {
        if (window.sendCommunication !== undefined) {
            window.sendCommunication({
                open: true,
                onClose: () => console.log('sms close'),
                id: props.contact?.id,
                from: "deal-details",
                fetchContactDetails: false,
                contactInfo: props.contact,
                sendSuccessCallBack: () => {
                    setLastCommunicationMessage("A few seconds ago");
                    props.reloadNeedForConversation(true);
                }
            }, 'sms')
        }
    }

    const getUserCampaignRegistrationStatus = () => {
        try {
            getUserCampaignRegistrationStatusApi()
                .then((res) => {
                    res = res.data;
                    if (res.success) {
                        if (res.campaignRegistrationStatus === "TRUE") {
                            sendSMSModal();
                        } else {
                            window.globalSmsRestriction(true, {
                                callback: (res) => {
                                    if (res.sendWithRisk) {
                                        sendSMSModal();
                                    }
                                }
                            });
                        }
                    }
                })
                .catch((err) => {
                    sendSMSModal();
                    console.log(err);
                });
        } catch (err) {
            sendSMSModal();
            console.log(err);
        }
    }

    const callAll = () => {
        props.closeModal();
        window.initiateGlobalDialer(props.contact);
    }

    const sendVoiceMail = () => {
        if (window.sendCommunication !== undefined) {
            window.sendCommunication({
                open: true,
                onClose: () => console.log('RVM close'),
                id: props.contact.id,
                from: "contact-details",
                fetchContactDetails: false,
                sendSuccessCallBack: () => props.reloadNeedForConversation(true)
            }, 'voice')
        }
    }

    const selectActiveMailType = (type) => {

        setMailOptionVisible(false)
        window.openGlobalDirectMailModal({
            messageType: type, contactId: props.contact.id, contactDetails: props.contact,
            callBack: submitDirectMail, from: 'inbox'
        })
    }

    const submitDirectMail = () => {
        console.log('submit Direct Mail')
    }

    const handleNewPostcard = (type = 'postcard') => {
        setMailOptionVisible(false)
        props.closeModal();
        window.sendGlobalDirectMailV2({
            contactId: props.contact.id,
            from: '/deal-stages',
            card_type: type,
            for: 'direct-mail-send'
        })
    }
    const updateAddNote = (message = "") => {
        if (isEdit) {
            let allNotes = [...notes];
            allNotes[editIndex].message = message;
            setNotes(allNotes);
            storeEditData(null);
        } else {
           // getContactNotes();
        }
    };


    return (
        <div
            className=''
            style={{
                border: "1px solid",
                borderRadius: "5px",
                padding: "5px",
                marginTop: '16px',
                backgroundColor: "#ffff",
            }}
        >
            <div className='text-center'>
                <div className=' d-flex justify-content-center ' style={{ gap: '10px' }}>
                    {props.contact?.number &&
                        props.contact?.number !== "" && (
                            <BootstrapTooltip title="Text Message" arrow placement="top">
                                <span
                                    className={`${actionIconButton} `}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if (Utils.getAccountData("campaignRegistrationAvailable") === "TRUE") {
                                            if (window.sendCommunication !== undefined) {
                                                window.sendCommunication({
                                                    open: true,
                                                    onClose: () => console.log('sms close'),
                                                    id: props.contact?.id,
                                                    from: "deal-details",
                                                    fetchContactDetails: false,
                                                    contactInfo: props.contact,
                                                    sendSuccessCallBack: () => {
                                                        setLastCommunicationMessage("A few seconds ago");
                                                        props.reloadNeedForConversation(true);
                                                    }
                                                }, 'sms')
                                            }
                                        } else {
                                            getUserCampaignRegistrationStatus();
                                        }
                                    }}
                                >
                                    {IconList.massageIcon}
                                </span>
                            </BootstrapTooltip>
                        )}
                    {(props.contact?.email && props.contact?.email !== "") && (
                        <BootstrapTooltip title="Email" arrow placement="top">
                            <span
                                className={`${actionIconButton} `}
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (window.sendCommunication !== undefined) {
                                        window.sendCommunication({
                                            open: true,
                                            onClose: () => console.log('Email Close'),
                                            id: props.contact?.id,
                                            from: "contact-details",
                                            primaryEmail: getContactEmail(),
                                            fetchContactDetails: false,
                                            sendSuccessCallBack: () => {
                                                setLastCommunicationMessage("A few seconds ago");
                                                props.reloadNeedForConversation(true);
                                            }
                                        }, 'email')
                                    }
                                }}
                            >
                                {IconList.emailIconV2}
                            </span>
                        </BootstrapTooltip>
                    )}
                    {props.contact?.number &&
                        props.contact?.number !== "" && (
                            <BootstrapTooltip title="Call" arrow placement="top">
                                <span
                                    className={`${actionIconButton} `}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        (Utils.getAccountData("dnc")
                                            && props.contact && props.contact?.number) ?
                                            (
                                                (
                                                    props.contact?.contact_additional_informations &&
                                                    props.contact?.contact_additional_informations.dnc_status === "DO_NOT_CALL"
                                                ) ? (
                                                    dncNoCall({
                                                        takeRisk: () => {
                                                            callAll(props.contact)
                                                        },
                                                        cancelButtonText: "Don't Call"
                                                    })
                                                ) : (
                                                    callAll(props.contact)
                                                )
                                            ) : (
                                                callAll(props.contact)
                                            )
                                    }}

                                >
                                    {IconList.callIconv2}
                                </span>
                            </BootstrapTooltip>
                        )}
                    {props.contact?.number &&
                        props.contact?.number !== "" && (
                            <BootstrapTooltip title="RVM" arrow placement="top">
                                <span
                                    className={`${actionIconButton} `}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        {
                                            (Utils.getAccountData("carrierLookup")
                                                && props.contact && props.contact.number) ?
                                                (
                                                    (
                                                        props.contact?.contact_additional_informations &&
                                                        props.contact?.contact_additional_informations.dnc_status === "DO_NOT_CALL"
                                                    ) ? (
                                                        dncNoCall({
                                                            takeRisk: () => {
                                                                sendVoiceMail()
                                                            },
                                                            cancelButtonText: "Don't Send"
                                                        })
                                                    ) : (
                                                        sendVoiceMail()
                                                    )
                                                ) : (
                                                    sendVoiceMail()
                                                )
                                        }
                                    }}
                                >
                                    {IconList.micIcon}
                                </span>
                            </BootstrapTooltip>
                        )}

                    {props.contact?.email && props.contact.email !== "" && (
                        <BootstrapTooltip title="Video email" arrow placement="top">
                            <span
                                className={`${actionIconButton} `}
                                onClick={() => window.sendCommunication({
                                    open: true,
                                    contactId: props.contact?.id,
                                    contactDetails: props.contact,
                                    from: 'contact-details',
                                    responseCallback: () => props.reloadNeedForConversation(true)
                                }, 'videoEmail')}
                                style={{
                                    ':hover': {
                                        cursor: 'pointer'
                                    }
                                }}
                            >
                                <VideoCallIcon style={{ fontSize: '30px', marginTop: '8px' }} />
                            </span>
                        </BootstrapTooltip>
                    )}



                    {(thanksIoActive || zenDirectActive) && !Helper.isEmpty(props.contact?.address) && !Helper.isEmpty(props.contact.state) &&
                        <BootstrapTooltip title="DMail" arrow placement="top">
                            <span
                                className={`${actionIconButton} `}
                                onClick={(e) => setMailOptionVisible(e.currentTarget)}
                            >
                                {IconList.directMailiconInContactDetailsWithoutStroke}
                            </span>
                        </BootstrapTooltip>
                    }
                    <Menu

                        id="more-menu"
                        anchorEl={mailOptionVisible}
                        keepMounted
                        open={Boolean(mailOptionVisible)}
                        onClose={() => setMailOptionVisible(false)}
                    >
                        {Utils.getAccountData('userId') == 1 &&
                            <MenuItem onClick={() => selectActiveMailType(GIFT)}
                                style={{
                                    display: 'flex',
                                    paddingTop: 6,
                                    paddingBottom: 6, paddingLeft: 16, paddingRight: 16, justifyContent: 'unset'
                                }}>

                                <span>
                                    {IconList.giftIcon}

                                </span>
                                &nbsp; Old Gift</MenuItem>
                        }

                        {thanksIoActive &&
                            <>
                                <MenuItem onClick={() => handleNewPostcard('postcard')}
                                    style={{
                                        display: 'flex',
                                        paddingTop: 6,
                                        paddingBottom: 6, paddingLeft: 16, paddingRight: 16, justifyContent: 'unset'
                                    }}
                                >
                                    <span>{IconList.giftIcon}</span>&nbsp; Postcard
                                </MenuItem>
                                <MenuItem onClick={() => handleNewPostcard('letter')}
                                    style={{
                                        display: 'flex',
                                        paddingTop: 6,
                                        paddingBottom: 6, paddingLeft: 16, paddingRight: 16, justifyContent: 'unset'
                                    }}
                                >
                                    <span>{IconList.giftIcon}</span>&nbsp; Letter
                                </MenuItem>
                                <MenuItem onClick={() => handleNewPostcard('notecard')}
                                    style={{
                                        display: 'flex',
                                        paddingTop: 6,
                                        paddingBottom: 6, paddingLeft: 16, paddingRight: 16, justifyContent: 'unset'
                                    }}
                                >
                                    <span>{IconList.giftIcon}</span>&nbsp; Notecard
                                </MenuItem>
                            </>
                        }
                        {zenDirectActive &&
                            <MenuItem onClick={() => handleNewPostcard('zendirect_gift')}
                                style={{
                                    display: 'flex',
                                    paddingTop: 6,
                                    paddingBottom: 6, paddingLeft: 16, paddingRight: 16, justifyContent: 'unset'
                                }}
                            >
                                <span>{IconList.giftIcon}</span>&nbsp; Gift
                            </MenuItem>
                        }
                    </Menu>
                    <BootstrapTooltip title="Add Note" arrow placement="top">
                        <span
                            className={`${actionIconButton} `} onClick={toggleAddNoteModal}
                        >
                            <NoteAddOutlined style={{ fontSize: '30px', marginTop: '8px' }} />
                        </span>
                    </BootstrapTooltip>
                </div>
            </div>
            {showAddNoteModal && (

                <NoteComponent
                    open={showAddNoteModal}
                    onClose={toggleAddNoteModal}
                    data={editData}
                    isEdit={isEdit}
                    successCallback={updateAddNote}
                    contactId={props.contact?.id}
                />
            )}
        </div>
    );
};

const mapStateToProps = state => {
    return {
        contact: state.addContactReducer.contact,

    };
};

const mapDispatchToProps = dispatch => {
    return {

    };
}
export default connect(mapStateToProps, mapDispatchToProps)(ContactActions);

import { useCallback, useContext, useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Utils from "../../Helpers/Utils";
import FavouriteIcon from "./Icon/FavouriteIcon";
import FavouriteIcon2 from "./Icon/FavouriteIcon2";
import DownloadIcon from "./Icon/DownloadIcon";
import PersonIcon from "./Icon/PersonIcon";
import UnreadIcon from "./Icon/UnreadIcon";
import ConversationCloseIcon from "./Icon/ConversationCloseIcon";
import DeleteIcon from "./Icon/DeleteIcon";
import { addSelectedContact, archiveLead, deleteLead, markAsImportant, markAsUnread, moveToInboxFromArchive, toggleRightSidebar, updateInboxUnreadContactInfo, updateSelectedContact } from "../inboxAction";
import { connect } from "react-redux";
import NewConfirmAlert from "../../alert/NewConfirmAlert";
import CoreConstants from "../constants/CoreConstants";

const ConversationTopBar = (props) => {

  const [contact, setContact] = useState();
  const [showMarkAs, setShowMarkAs] = useState(true);
  const [threads, setThreads] = useState([]);
  const [changeArrowDir,setChangeArrowDir]=useState(false)

  useEffect(() => {
    setContact(props.selectedContact)
  }, [props.contactDetails]);



  const getContactLabel = (name, number, email) => {
    if (name == "" || name == " " || name == null || name == "null null") {
      if (number == "" || number == " ") {
        if (email == "" || email == " ") {
          return "[No Name]";
        } else {
          return email.length > 30 ? email.substr(0, 38) + " ..." : email;
        }
      } else {
        return number;
      }
    }
    return name.length > 30 ? name.substr(0, 28) + " ..." : name;
  };
  const markImportant = (e, importantStatus) => {
    e.preventDefault();

    let formData = new FormData();
    formData.append('contact_id', contact.id);
    formData.append('important_status', importantStatus);
    props.markAsImportant(formData, (response) => {
        response.action_type = 'update';
        response.update_field = 'is_favourite';
        responseAction(response);
    });
}

  const responseAction = (response) => {
    console.log(response);
    if (response.status) {
      if (typeof response.is_favourite !== "undefined") {
        setContact({
          ...contact,
          is_favourite: parseInt(response.is_favourite),
        });
      } else if (typeof response.is_archived !== "undefined") {
        setContact({ ...contact, is_archived: parseInt(response.is_archived) });
    
      }

      window.showNotification("SUCCESS", response.html);
      // window.loadInboxThreads();
    } else {
      if (response.is_archived) {
        window.showNotification("ERROR", response.message);
      }
      // window.showNotification('ERROR', response.html);
    }
  };
// const markUnread = () => {
//   updateInboxThreadForUnread()
    
// }
const updateInboxThreadForUnread=()=>{

  let tmpThreads = [...threads]
      if(tmpThreads[props.selectedContactIndex]?.is_read == CoreConstants.inboxThread.READ){
          tmpThreads[props.selectedContactIndex].is_read = CoreConstants.inboxThread.UNREAD;
          setThreads(tmpThreads)
          window.showNotification('SUCCESS','message marked as unread')
          // props.updateInboxUnreadContact(0)
          let formData = new FormData();
          formData.append('contact_id', tmpThreads[props.selectedContactIndex].contact.id);
          markAsUnread(formData)
          .then(response => {
              if(window.updateNotificationCount){
                  window.updateNotificationCount(false, true)
              }
          });
      } 
}

const markUnread = (e) => {
  e.preventDefault();

  let formData = new FormData();
  formData.append('contact_id', contact.id);

  props.markAsUnread(formData, (response) => {
      if (response.status) {
        window.showNotification('SUCCESS','message marked as unread')

        
      } else {
          window.showNotification('ERROR', response.html);
      }
  });
  // window.updateSelectedContactData()
}
const closeConversation = (e) => {
  e.preventDefault();

  let formData = new FormData();
  formData.append('contact_id', contact.id);

  // props.archiveLead(formData, responseAction);
  props.archiveLead({'contact_id': contact.id}, responseAction);
}
const toggleRightSidebarIcon = useCallback((e) => {
  e.preventDefault();
  document.querySelector("#main__content").classList.toggle("right_sidebar_active");
  setChangeArrowDir(!changeArrowDir)
}, [changeArrowDir])


const removeContact = () => {
  NewConfirmAlert({
      title: 'Confirm to delete',
      description: 'Are you sure to delete contact?',
      cancelText: "Cancel",
      submitText: "Yes, Delete!",
      onSubmit: async () => {
        let formData = new FormData();
        formData.append('contact_id', contact.id);
        try {
            props.deleteLead(formData, responseAction);
        } catch(error) {
            console.log(error)
        } finally {
            props.storeSelectedContact(null)
            props.handleClose()
            props.dealCallBack(true)
            window.location.reload();
            return;
        }
      }
  })
}

  return (
    <div
      style={{
        borderBottom: "1px solid lightgray",
        marginBottom: "12px",
        marginLeft: "20px !important",
        marginTop: "4px",
      }}
      className="col s12 m12 l12 xl6 main-inbox_column "
    >
      <div
        onClick={props.handleClose}
        // className="inbox_toggle_btn switch-icon"
        // style={changeArrowDir ? { transform: "rotate(180deg)" } : {}}
        style={{
          background: "#3c7ef3",
          width: "22px",
          height: "45px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          // borderTopRightRadius: "10px",
          borderBottomRightRadius: "10px",
          position: "absolute",
          left: "-5px",
          top: 0,
          cursor: "pointer",
        }}
      >
        <svg
          width="14"
          height="13"
          viewBox="0 0 14 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.15118 1.57604L12.1408 6.58429L7.15118 11.5925L6.26288 10.6946L9.72788 7.21664H0.800781V5.95193H9.72788L6.26288 2.47398L7.15118 1.57604ZM12.1408 6.58429V12.9078H13.4008V0.260742H12.1408V6.58429Z"
            fill="white"
          ></path>
        </svg>
      </div>
      <div className="mt-0  b-radius-10 box-shadow-none mb-0">
        <div className="mx-20 top-action-bar p-relative">
          <div
            className="row d-flex align-items-center"
            style={{ justifyContent: "space-between", marginBottom: "12px" }}
          >
            <div className="col s6 " style={{ paddingLeft: "35px" }}>
              <div className="user-name-area flex align-items-center">
                <div className="user-image flex align-items-center ">
                  {/*<img src="./images/inbox/ryan.svg" alt="" className="circle responsive-img"/>*/}
                  <Avatar>
                    {Utils.getInitial(
                      (props.contactDetails?.first_name
                        ? props.contactDetails?.first_name
                        : "") +
                        " " +
                        (props.contactDetails?.last_name
                          ? props.contactDetails?.last_name
                          : "")
                    ).toUpperCase()}
                  </Avatar>
                </div>
                <div className="content pl-2">
                  <div
                    className="d-flex align-items-center"
                    style={{ gap: "6px" }}
                  >
                    <a href={`/contacts/${props.contactDetails?.id}`}>
                      {props.contactDetails?.id !== ""
                        ? getContactLabel(
                            props.contactDetails?.first_name +
                              " " +
                              props.contactDetails?.last_name,
                            props.contactDetails?.number,
                            props.contactDetails?.email
                          )
                        : ""}
                    </a>
                  </div>
                </div>
                <div className="content">
                  {/*<p>{getContactLabel((contact.first_name ? contact.first_name : '') + ' ' + (contact.last_name ? contact.last_name : ''), contact.number, contact.email)}</p>*/}
                  <div
                    style={{ gap: "6px" }}
                  >
                    {Utils.getAccountData("carrierLookup") &&
                      props.contactDetails &&
                      props.contactDetails.number &&
                      props.contactDetails.number.trim() !== "" &&
                      props.contactDetails.contact_additional_informations &&
                      props.contactDetails.contact_additional_informations.dnc_status === "DO_NOT_CALL" && (
                        <div style={{background:'red',padding:'0px 8px',borderRadius:'16px',color:'white',fontSize:'12px',marginLeft:'4px'}}>DNC</div>
                      )}
                  </div>
                  {/*<span>(Active 5m ago)</span>*/}
                </div>
              </div>
            </div>
            <div className="col s6">
              <div
                className="action-icons d-flex justify-content-end"
                style={{ paddingRight: "4px" }}
              >
                <ul className="p-0 m-0 right flex" style={{ gap: "16px" }}>
                  {(contact?.is_favourite == null ||
                    contact?.is_favourite === 0) && (
                    <li className={showMarkAs ? "" : "disable-action-option"}>
                      <a
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                          border: "1px solid black",
                          borderRadius: "50%",
                          padding: "5px",
                        }}
                        href="#"
                        title={`${
                          showMarkAs
                            ? "Mark as important"
                            : "Only lead owner can change"
                        }`}
                        onClick={(e) =>
                          showMarkAs ? markImportant(e, 1) : null
                        }
                      >
                        <FavouriteIcon
                          style={{ height: "30px", width: "30px" }}
                        />
                      </a>
                    </li>
                  )}
                  {contact?.is_favourite !== null &&
                    contact?.is_favourite == 1 && (
                      <li className={showMarkAs ? "" : "disable-action-option"}>
                        <a
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                            border: "1px solid black",
                            borderRadius: "50%",
                            padding: "5px",
                          }}
                          href="#"
                          title={`${
                            showMarkAs
                              ? "Remove from important"
                              : "Only lead owner can change"
                          }`}
                          onClick={(e) =>
                            showMarkAs ? markImportant(e, 0) : null
                          }
                        >
                          <FavouriteIcon2 />
                        </a>
                      </li>
                    )}

                  {/* <li
                    className="conversation_export_loader"
                    title="Export conversation as PDF"
                  >
                    <a style={{display:'flex',alignItems:'center',justifyContent:'center',cursor:'pointer',border:'1px solid black',borderRadius:'50%',padding:'5px'}} 
                    href="#">
                      <DownloadIcon />
                    </a>
                    <div
                      id="export__conversation_container"
                      style={{ display: "none" }}
                    />
                  </li> */}
                  <li>
                    <a
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                        border: "1px solid black",
                        borderRadius: "50%",
                        padding: "5px",
                      }}
                      href={`/contacts/${contact?.id}`}
                      // onClick={(e) => {e.preventDefault();history.push(`/contacts/${contact.id}`);}}
                      title="Contact timeline"
                    >
                      <PersonIcon />
                    </a>
                  </li>
                  <li>
                    <a
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                        border: "1px solid black",
                        borderRadius: "50%",
                        padding: "5px",
                      }}
                      href="#"
                      title="Mark as unread"
                      onClick={(e) => markUnread(e)}
                    >
                      <UnreadIcon />
                    </a>
                  </li>
                  <li>
                    <a
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                        border: "1px solid black",
                        borderRadius: "50%",
                        padding: "5px",
                      }}
                      href="#"
                      title="Delete contact"
                      onClick={(e) => removeContact()}
                    >
                      <DeleteIcon />
                    </a>
                  </li>
                  <li>
                    <a
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                        border: "1px solid black",
                        borderRadius: "50%",
                        padding: "5px",
                      }}
                      href="#"
                      onClick={(e) => closeConversation(e)}
                      title="Close conversation"
                    >
                      <ConversationCloseIcon />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
      selectedContact: state.inboxReducer.selectedContact,
      selectedContactIndex: state.inboxReducer.selectedContactIndex,
      conversations: state.inboxReducer.conversations,
      contactDetails: state.rightsideReducer.contact,
      storedUnreadContactId: state.inboxReducer.updateUnreadContactId
  };
};

const mapDispatchToProps = dispatch => {
  return {
      updateSelectedContact: (params) => dispatch(updateSelectedContact(params)),
      markAsImportant: (params, callback) => dispatch(markAsImportant(params, callback)),
      markAsUnread: (params, callback) => dispatch(markAsUnread(params, callback)),
      deleteLead: (params, callback) => dispatch(deleteLead(params, callback)),
      archiveLead: (params, callback) => dispatch(archiveLead(params, callback)),
      moveToInboxFromArchive: (params, callback) => dispatch(moveToInboxFromArchive(params, callback)),
      storeSelectedContact: (params) => dispatch(addSelectedContact(params)),
      toggleRightSidebar: () => dispatch(toggleRightSidebar()),
      updateInboxUnreadContact: (params)=> dispatch(updateInboxUnreadContactInfo(params))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConversationTopBar);



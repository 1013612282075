export const iconList = {
    Dashboard: <svg className="icon_ds_side_bar" width="18" height="15" viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.57747 20.4507H3.60563V10.8169H0L12 0L24 10.8169H20.3944V20.4507H14.4225V13.2394H9.57747V20.4507Z"></path>
</svg>,
    Inbox: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.0282 12C18.0282 12.338 17.8967 12.6197 17.6338 12.8451C17.4085 13.0704 17.1268 13.1831 16.7887 13.1831H4.78873L0 18.0282V1.1831C0 0.84507 0.112676 0.56338 0.338028 0.338028C0.56338 0.112676 0.84507 0 1.1831 0H16.7887C17.1268 0 17.4085 0.112676 17.6338 0.338028C17.8967 0.56338 18.0282 0.84507 18.0282 1.1831V12ZM22.8169 4.78873C23.1549 4.78873 23.4366 4.90141 23.662 5.12676C23.8873 5.35211 24 5.6338 24 5.97183V24L19.2113 19.2113H5.97183C5.6338 19.2113 5.35211 19.0986 5.12676 18.8732C4.90141 18.6479 4.78873 18.3662 4.78873 18.0282V15.6056H20.3944V4.78873H22.8169Z" fill="white"></path>
</svg>,
    Deals: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 0C5.3832 0 0 5.3832 0 12C0 18.6168 5.3832 24 12 24C18.6168 24 24 18.6168 24 12C24 5.3832 18.6168 0 12 0ZM13.2 17.898V19.2H10.8V17.904C7.9932 17.4636 7.2 15.5016 7.2 14.4H9.6C9.6132 14.5716 9.7908 15.6 12 15.6C13.656 15.6 14.4 14.898 14.4 14.4C14.4 14.0112 14.4 13.2 12 13.2C7.824 13.2 7.2 10.944 7.2 9.6C7.2 8.0544 8.4348 6.4992 10.8 6.102V4.8144H13.2V6.1452C15.2808 6.6372 16.08 8.3688 16.08 9.6H14.88L13.68 9.6216C13.6632 9.1656 13.422 8.4 12 8.4C10.4412 8.4 9.6 9.0192 9.6 9.6C9.6 10.0488 9.6 10.8 12 10.8C16.176 10.8 16.8 13.056 16.8 14.4C16.8 15.9456 15.5652 17.5008 13.2 17.898Z" fill="white"></path>
</svg>,
    Calender: <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.2084 15.6253V17.9859H4.77752V15.6253H13.2084ZM19.1663 21.5831V8.43091H2.41686V21.5831H19.1663ZM19.1663 2.41686C19.8033 2.41686 20.3653 2.66042 20.8525 3.14754C21.3396 3.63466 21.5831 4.19672 21.5831 4.83372V21.5831C21.5831 22.2201 21.3396 22.7822 20.8525 23.2693C20.3653 23.7564 19.8033 24 19.1663 24H2.41686C1.74239 24 1.16159 23.7564 0.674473 23.2693C0.224824 22.7822 0 22.2201 0 21.5831V4.83372C0 4.19672 0.224824 3.63466 0.674473 3.14754C1.16159 2.66042 1.74239 2.41686 2.41686 2.41686H3.59719V0H6.01405V2.41686H15.5691V0H17.9859V2.41686H19.1663ZM16.8056 10.7916V13.2084H4.77752V10.7916H16.8056Z" fill="white"></path>
</svg>,
    Contacts: <svg width="24" height="17" viewBox="0 0 24 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.39437 9.57747C9.22066 9.57747 10.1784 9.69014 11.2676 9.91549C9.35211 10.9671 8.39437 12.3568 8.39437 14.0845V16.7887H0V13.8028C0 12.939 0.507042 12.169 1.52113 11.493C2.57277 10.8169 3.71831 10.3286 4.95775 10.0282C6.19718 9.7277 7.34272 9.57747 8.39437 9.57747ZM13.0704 11.7183C14.5728 11.1174 16.0188 10.8169 17.4085 10.8169C18.7981 10.8169 20.2441 11.1174 21.7465 11.7183C23.2488 12.3193 24 13.108 24 14.0845V16.7887H10.8169V14.0845C10.8169 13.108 11.5681 12.3193 13.0704 11.7183ZM10.9296 6.14085C10.216 6.85446 9.37089 7.21127 8.39437 7.21127C7.41784 7.21127 6.57277 6.85446 5.85916 6.14085C5.14554 5.42723 4.78873 4.58216 4.78873 3.60563C4.78873 2.62911 5.14554 1.78404 5.85916 1.07042C6.57277 0.356808 7.41784 0 8.39437 0C9.37089 0 10.216 0.356808 10.9296 1.07042C11.6432 1.78404 12 2.62911 12 3.60563C12 4.58216 11.6432 5.42723 10.9296 6.14085ZM19.493 7.5493C18.9296 8.11268 18.2347 8.39437 17.4085 8.39437C16.5822 8.39437 15.8685 8.11268 15.2676 7.5493C14.7042 6.94836 14.4225 6.23474 14.4225 5.40845C14.4225 4.58216 14.7042 3.86855 15.2676 3.26761C15.8685 2.66667 16.5822 2.3662 17.4085 2.3662C18.2347 2.3662 18.9296 2.66667 19.493 3.26761C20.0939 3.86855 20.3944 4.58216 20.3944 5.40845C20.3944 6.23474 20.0939 6.94836 19.493 7.5493Z" fill="white"></path>
</svg>,
    Marketing: <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.36599 3.85938C6.59193 3.85938 5.14847 5.24511 5.14847 6.9463C5.13681 9.43475 8.24373 11.4981 8.36599 11.5815C8.36599 11.5815 11.5952 9.43475 11.5835 6.94823C11.5835 5.24511 10.1401 3.85938 8.36599 3.85938ZM8.36599 8.49265C7.47715 8.49265 6.75723 7.80152 6.75723 6.94823C6.75723 6.09493 7.47715 5.4038 8.36599 5.4038C9.25483 5.4038 9.97475 6.09493 9.97475 6.94823C9.97475 7.80152 9.25483 8.49265 8.36599 8.49265Z" fill="white"></path>
    <path d="M16.0878 14.1572H15.071L14.7107 13.8097C15.9719 12.3425 16.7313 10.4377 16.7313 8.36564C16.7313 3.74523 12.986 0 8.36564 0C3.74523 0 0 3.74523 0 8.36564C0 12.986 3.74523 16.7313 8.36564 16.7313C10.4377 16.7313 12.3425 15.9719 13.8097 14.7107L14.1572 15.071V16.0878L20.5923 22.51L22.51 20.5923L16.0878 14.1572ZM8.36564 14.1572C5.16095 14.1572 2.57404 11.5703 2.57404 8.36564C2.57404 5.16095 5.16095 2.57404 8.36564 2.57404C11.5703 2.57404 14.1572 5.16095 14.1572 8.36564C14.1572 11.5703 11.5703 14.1572 8.36564 14.1572Z" fill="white"></path>
</svg>,
    FindNewLeads: <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.36599 3.85938C6.59193 3.85938 5.14847 5.24511 5.14847 6.9463C5.13681 9.43475 8.24373 11.4981 8.36599 11.5815C8.36599 11.5815 11.5952 9.43475 11.5835 6.94823C11.5835 5.24511 10.1401 3.85938 8.36599 3.85938ZM8.36599 8.49265C7.47715 8.49265 6.75723 7.80152 6.75723 6.94823C6.75723 6.09493 7.47715 5.4038 8.36599 5.4038C9.25483 5.4038 9.97475 6.09493 9.97475 6.94823C9.97475 7.80152 9.25483 8.49265 8.36599 8.49265Z" fill="white"></path>
    <path d="M16.0878 14.1572H15.071L14.7107 13.8097C15.9719 12.3425 16.7313 10.4377 16.7313 8.36564C16.7313 3.74523 12.986 0 8.36564 0C3.74523 0 0 3.74523 0 8.36564C0 12.986 3.74523 16.7313 8.36564 16.7313C10.4377 16.7313 12.3425 15.9719 13.8097 14.7107L14.1572 15.071V16.0878L20.5923 22.51L22.51 20.5923L16.0878 14.1572ZM8.36564 14.1572C5.16095 14.1572 2.57404 11.5703 2.57404 8.36564C2.57404 5.16095 5.16095 2.57404 8.36564 2.57404C11.5703 2.57404 14.1572 5.16095 14.1572 8.36564C14.1572 11.5703 11.5703 14.1572 8.36564 14.1572Z" fill="white"></path>
</svg>,
    Triggers: <svg width="16" height="24" viewBox="0 0 16 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.21652 13.3297C5.07916 13.3297 4.97303 13.3297 4.86064 13.3297C3.50582 13.3297 2.15724 13.3297 0.802415 13.3297C0.153098 13.3297 -0.1341 12.9489 0.0594465 12.337C1.27067 8.41614 2.49438 4.50151 3.7181 0.580639C3.85545 0.137355 4.049 0 4.51726 0C6.65875 0 8.80649 0 10.948 0C11.5536 0 11.8346 0.387093 11.6597 0.973975C11.0042 3.17166 10.3424 5.37559 9.68057 7.57328C9.64311 7.69815 9.61189 7.82926 9.56819 7.99783C9.73676 7.99783 9.86787 7.99783 9.99899 7.99783C11.6348 7.99783 13.2643 8.00408 14.9001 7.99159C15.231 7.99159 15.4994 8.06651 15.6493 8.37868C15.7991 8.6971 15.6805 8.95308 15.4745 9.20282C11.5536 13.9915 7.63273 18.7865 3.71185 23.5752C3.59947 23.7063 3.4746 23.8437 3.32476 23.9248C3.08127 24.0622 2.83777 23.9997 2.6255 23.8312C2.39449 23.6501 2.3383 23.4004 2.41322 23.1257C2.53809 22.6512 2.68169 22.1891 2.81904 21.7146C3.59323 19.0175 4.37366 16.3203 5.14784 13.6294C5.17282 13.5482 5.1853 13.4608 5.21652 13.3297Z" fill="white"></path>
</svg>,
    Reporting: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.0282 12C18.0282 12.338 17.8967 12.6197 17.6338 12.8451C17.4085 13.0704 17.1268 13.1831 16.7887 13.1831H4.78873L0 18.0282V1.1831C0 0.84507 0.112676 0.56338 0.338028 0.338028C0.56338 0.112676 0.84507 0 1.1831 0H16.7887C17.1268 0 17.4085 0.112676 17.6338 0.338028C17.8967 0.56338 18.0282 0.84507 18.0282 1.1831V12ZM22.8169 4.78873C23.1549 4.78873 23.4366 4.90141 23.662 5.12676C23.8873 5.35211 24 5.6338 24 5.97183V24L19.2113 19.2113H5.97183C5.6338 19.2113 5.35211 19.0986 5.12676 18.8732C4.90141 18.6479 4.78873 18.3662 4.78873 18.0282V15.6056H20.3944V4.78873H22.8169Z" fill="white"></path>
</svg>,
    Settings: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.73709 14.9859C9.56338 15.8122 10.5587 16.2254 11.723 16.2254C12.8873 16.2254 13.8826 15.8122 14.7089 14.9859C15.5352 14.1596 15.9484 13.1643 15.9484 12C15.9484 10.8357 15.5352 9.84038 14.7089 9.01408C13.8826 8.18779 12.8873 7.77465 11.723 7.77465C10.5587 7.77465 9.56338 8.18779 8.73709 9.01408C7.9108 9.84038 7.49765 10.8357 7.49765 12C7.49765 13.1643 7.9108 14.1596 8.73709 14.9859ZM20.6808 13.1831L23.216 15.1549C23.4789 15.3427 23.5164 15.6056 23.3286 15.9437L20.9061 20.1127C20.7559 20.3756 20.5117 20.4507 20.1737 20.338L17.1878 19.1549C16.3991 19.7183 15.723 20.1127 15.1596 20.338L14.7089 23.493C14.6338 23.831 14.446 24 14.1455 24H9.30047C9 24 8.81221 23.831 8.73709 23.493L8.28638 20.338C7.57277 20.0376 6.89671 19.6432 6.25822 19.1549L3.2723 20.338C2.93427 20.4507 2.69014 20.3756 2.53991 20.1127L0.117371 15.9437C-0.0704225 15.6056 -0.0328638 15.3427 0.230047 15.1549L2.76526 13.1831C2.7277 12.9202 2.70892 12.5258 2.70892 12C2.70892 11.4742 2.7277 11.0798 2.76526 10.8169L0.230047 8.84507C-0.0328638 8.65728 -0.0704225 8.39437 0.117371 8.05634L2.53991 3.88732C2.69014 3.62441 2.93427 3.5493 3.2723 3.66197L6.25822 4.84507C7.04695 4.28169 7.723 3.88732 8.28638 3.66197L8.73709 0.507042C8.81221 0.169014 9 0 9.30047 0H14.1455C14.446 0 14.6338 0.169014 14.7089 0.507042L15.1596 3.66197C15.8732 3.96244 16.5493 4.35681 17.1878 4.84507L20.1737 3.66197C20.5117 3.5493 20.7559 3.62441 20.9061 3.88732L23.3286 8.05634C23.5164 8.39437 23.4789 8.65728 23.216 8.84507L20.6808 10.8169C20.7183 11.0798 20.7371 11.4742 20.7371 12C20.7371 12.5258 20.7183 12.9202 20.6808 13.1831Z" fill="white"></path>
</svg>,
    Training: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 0C5.37321 0 0 5.37321 0 12C0 18.6268 5.37321 24 12 24C18.6268 24 24 18.6268 24 12C24 5.37321 18.6268 0 12 0ZM15.8598 12.1848L10.0098 16.4411C9.97775 16.4641 9.93995 16.4779 9.90056 16.4808C9.86118 16.4838 9.82175 16.4758 9.78659 16.4578C9.75143 16.4399 9.72192 16.4125 9.70129 16.3788C9.68066 16.3452 9.66971 16.3065 9.66964 16.267V7.75982C9.66951 7.72025 9.68034 7.68141 9.70093 7.64762C9.72153 7.61383 9.75107 7.58639 9.7863 7.56837C9.82152 7.55034 9.86106 7.54241 9.90051 7.54548C9.93996 7.54854 9.9778 7.56247 10.0098 7.58571L15.8598 11.8393C15.8875 11.8588 15.91 11.8847 15.9256 11.9148C15.9411 11.9448 15.9492 11.9782 15.9492 12.0121C15.9492 12.0459 15.9411 12.0793 15.9256 12.1093C15.91 12.1394 15.8875 12.1653 15.8598 12.1848Z" fill="white"></path>
</svg>,
    Accounts: <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.75 4.73684C4.75 7.34842 6.88117 9.47368 9.5 9.47368C12.1188 9.47368 14.25 7.34842 14.25 4.73684C14.25 2.12526 12.1188 0 9.5 0C6.88117 0 4.75 2.12526 4.75 4.73684ZM17.9444 20H19V18.9474C19 14.8853 15.6845 11.5789 11.6111 11.5789H7.38889C3.31444 11.5789 0 14.8853 0 18.9474V20H17.9444Z" fill="white"></path>
</svg>,
    EditOutlinedIcon: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000" class="bi bi-pencil-square" viewBox="0 0 16 16"><path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"></path><path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"></path></svg>
};
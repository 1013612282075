import HttpRequest from "./HttpRequest";

const backendUrlV1 = `${process.env.REACT_APP_JAVAEND_URL}/v1`;
const dealRootUrlV1 = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/deal`

const endpoints = {
    searchContactFromElastic: `${backendUrlV1}/contacts/get-elastic-contacts`,
    getUserPypelines: `${dealRootUrlV1}/select-all-pipeline-except-v2`,
    getUserPypelineStages: `${dealRootUrlV1}/select-all-stage-by-pipeline`,
    createDealV2: `${dealRootUrlV1}/user-contact-stage-products/create-deal`,
    updateDealV2: `${dealRootUrlV1}/user-contact-stage-products/update-deal`,
    getUserProducts: `${dealRootUrlV1}/user-products/list`,
    getContactStageProducts: `${dealRootUrlV1}/user-contact-stage-products/products`,
    contactStageProducts:`${dealRootUrlV1}/user-contact-stage-products/contact-stage-products`, 
    updateSuccessDeal: `${dealRootUrlV1}/user-contact-stage-products/update-success-deal`,
};

export const getElasticContacts = (payload) => {
    return HttpRequest.get(endpoints.searchContactFromElastic, payload);
};

export const getUserPypelines = (payload) => {
    return HttpRequest.post(endpoints.getUserPypelines, payload);
};

export const getUserPypelineStages = (payload) => {
    return HttpRequest.post(endpoints.getUserPypelineStages, payload);
};

export const createDealV2 = (payload) => {
    return HttpRequest.post(endpoints.createDealV2, payload);
};

export const updateDealV2 = (payload) => {
    return HttpRequest.put(`${endpoints.updateDealV2}/${payload.contact_stages_id}`, payload);
};

export const getUserProducts = (payload) => {
    return HttpRequest.get(endpoints.getUserProducts, payload);
};

export const getContactStageProducts = (payload) => {
    return HttpRequest.get(`${endpoints.getContactStageProducts}/${payload.contact_stages_id}`, payload);
};

export const getContactStageProductsApi = (payload) => {
    return HttpRequest.get(`${endpoints.contactStageProducts}/${payload}`);
};

export const updateSuccessDealApi = (payload) => {
    return HttpRequest.put(`${endpoints.updateSuccessDeal}/${payload.id}`, payload.payload);
};

export const getUserProductsApi = () => {
    return HttpRequest.get(`${endpoints.getUserProducts}`);
};
import React, { useContext, useState, useEffect } from "react";
import CustomAccordion, {
  CustomAccordionBody,
  CustomAccordionHeader,
} from "../../components/Accordion/CustomAccordion";
import { ContactDetailContext } from "../../reducer/GlobalReduers";
import AddIcon from "@material-ui/icons/Add";
import { icons } from "../../icons";
import ApiList from "../../ApiList";
import { List } from "./List";
import ListAltIcon from "@material-ui/icons/ListAlt";

const Appointment = (props) => {
  const [expand, setExpand] = useState(false);
  const [activity, setActivity] = useState([]);
  const [doneActivity, setDoneActivity] = useState([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);

  useEffect(() => {
    setActivity(props.activity);
  }, [props.activity]);

  useEffect(() => {
    if (expand) {
      getPeopleActivity();
    }
  }, [expand]);

  const refresh = () => {
    getPeopleActivity();
  };

  const getPeopleActivity = () => {
    setLoading(true);
    ApiList.getPeopleActivity({
      contact_id: props.contactDetails?.id,
    }).then((res) => {
      let response = res.data;
      if (response.success) {
        let filter_data = response.data.filter(
          (item) => parseInt(item.task_id) === 2 && parseInt(item.status) === 1
        );
        setActivity(filter_data);
        let filter_done_data = response.data.filter(
          (item) => parseInt(item.task_id) !== 2 && parseInt(item.status) === 2
        );
        setDoneActivity(filter_done_data);
      }
      setLoading(false);
    });
  };

  const editActivity = (id, data) => {
    window.openGlobalActivityModal({
      contactDetails: props.contactDetails,
      editData: data,
      callBack: getPeopleActivity,
      taskType: "Appointment",
    });
  };

  /* handle add appointment modal */
  const handleAddAppointment = (event, status) => {
    // event.stopPropagation()
    // setShowAddAppointModal(status)
    window.openGlobalActivityModal({
      subUserList: props.subUserList,
      contactDetails: props.contactDetails,
      // editData:this.state.editData,
      callBack: getPeopleActivity,
      taskType: "Appointment",
    });
  };

  const RenderList = ({ list }) => {
    const taskCount = list.reduce((count, item) => {
      if (
        props.from === "Appointment" &&
        item.task_id === 2 &&
        item.status !== 2
      ) {
        return count + 1;
      }
      return count;
    }, 0);

    useEffect(() => {
      setCount(taskCount);
    }, [list]);

    return list.map((item, index) => {
      if (
        props.from === "Appointment" &&
        item.task_id === 2 &&
        item.status !== 2
      ) {
        return (
          <List
            makeDone={""}
            data={item}
            key={index}
            index={index}
            status={1}
            expand={() => setExpand(false)}
            refresh={refresh}
            editActivity={editActivity}
          />
        );
      }
      return null;
    });
  };

  const renderDoneList = (list) => {
    let showList = [];
    list.map((item, index) => {
      showList.push(
        <List
          data={item}
          key={item.id}
          index={item.id}
          status={2}
          makeDone={null}
        />
      );
    });
    return showList;
  };

  return (
    <div className="" style={{ marginTop: "10px" }}>
      <CustomAccordion
        expand={expand}
        className="g-contact-details-collapsible"
      >
        <CustomAccordionHeader
          onClick={() => setExpand(!expand)}
          className={`${expand ? "g-collapsible-header-for-deal" : "g-collapsible-header"}  ${expand ? "expand" : ""}`}
        >
          <span className={`${expand ? "g-header-title-for-deal" : "g-header-title"}`}>
            {" "}
            <svg
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.5 0.84375C4.89111 0.84375 0.34375 5.39111 0.34375 11C0.34375 16.6001 4.90039 21.1562 10.5 21.1562C16.1084 21.1562 20.6562 16.6084 20.6562 11C20.6562 5.3999 16.1001 0.84375 10.5 0.84375ZM15.7734 12.5625H9.91406C9.86226 12.5625 9.81258 12.5419 9.77596 12.5053C9.73933 12.4687 9.71875 12.419 9.71875 12.3672V4.16406C9.71875 4.11226 9.73933 4.06258 9.77596 4.02596C9.81258 3.98933 9.86226 3.96875 9.91406 3.96875H11.0859C11.1377 3.96875 11.1874 3.98933 11.224 4.02596C11.2607 4.06258 11.2812 4.11226 11.2812 4.16406V11H15.7734C15.8252 11 15.8749 11.0206 15.9115 11.0572C15.9482 11.0938 15.9688 11.1435 15.9688 11.1953V12.3672C15.9688 12.419 15.9482 12.4687 15.9115 12.5053C15.8749 12.5419 15.8252 12.5625 15.7734 12.5625Z"
                fill={expand?"#fff":"#181f48"}
                
              ></path>
            </svg>{" "}
            Appointments <span className="g-header-count" style={{color:'black'}}>{count}</span>
          </span>
          <div className="g-header-action-content">
            <span
              className="g-add-task-button"
              style={{background:'white',height:'26px',width:'26px',display:'flex',justifyContent:'center',alignItems:'center',borderRadius:'50%'}}
              onClick={(event) => handleAddAppointment(event, true)}
            >
              <AddIcon
                style={{
                  fontSize:'14px',
                  color: "var(--light_blue)",
                }}
              />
            </span>
            <span className={`${expand?"g-custom-caret-for-deal":"g-custom-caret"}`}>{icons.caretIcon}</span>
          </div>
        </CustomAccordionHeader>
        <CustomAccordionBody className="g-collapsible-body" expand={expand}>
          <div className="g-contact-appointment-content">
            {loading && <span className="g-loading-content">Loading...</span>}
            {!loading && (
              <>
                <div
                  className="g-contact-appointment-list awesome__scroll_bar"
                  style={{ paddingBottom: "8px" }}
                >
                  {activity.length === 0 ? (
                    <span className="g-no-appointment">
                      No active task found
                    </span>
                  ) : (
                    <RenderList list={activity} />
                  )}
                </div>

                {/*{doneActivity.length > 0 &&*/}
                {/*<div className="g-contact-appointment-list awesome__scroll_bar">*/}
                {/*    <span className="g-done_activity_title">Mark as done appointment list : <span onClick={() => setShowDoneTask(!showDoneTask)} style={{textDecoration:'underline'}}>{showDoneTask ? 'Hide' : 'Show'}</span></span>*/}
                {/*    {showDoneTask && renderDoneList(doneActivity)}*/}
                {/*</div>*/}
                {/*}*/}
              </>
            )}
          </div>
        </CustomAccordionBody>
      </CustomAccordion>

      {/* add appointment modal */}
      {/* {showAddAppointModal &&
                 <LinkFile.addAppointment
                    open={showAddAppointModal}
                    onClose={() => {
                        setShowAddAppointModal(false);
                        setExpand(false);
                        setEditAppointment(null);
                        setEditData(null);
                    }}
                    taskId={2}
                    activityId={editAppointment}
                    editData={editData}
                />
            } */}
    </div>
  );
};
export default Appointment;

const inboxUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/inbox`;
const Api = {
    conversations: `${inboxUrl}/contact-conversation-v2`,
    markAsImportant: `${inboxUrl}/mark-as-important`,
    deleteContact: `${inboxUrl}/contact/delete`,
    archiveContact: `${inboxUrl}/contact/archive`,
    markAsUnread: `${inboxUrl}/mark-as-unread`,
    emails: `${inboxUrl}/user-email-list`,
    virtualNumbers: `${inboxUrl}/virtual-number-list`,
}

export default Api;